<template src="./purchase-vendor-items.html"></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import { GridId, AgFilter, AgAllowedAggFuncs, FilterParams, MultiFilterParams } from '@/core/components/common/grid/grid.const.js'
import { ItemWithCostSearchCriteria } from '@/backoffice/modules/item/itemWithCostSearchCriteria'
import * as ItemService from '@/backoffice/modules/item/item.service.js'

export default {
    name: 'purchaseVendorItems',
    props: {
        vendorCode: String,
        itemUpcs: Array,
        selectedColumnLabel: {
            type: String,
            default: () => {
                return 'General.Selected'
            }
        }
    },
    components: {
        grid,
        searchBar
    },
    data() {
        return {
            gridOptions: {
                getRowId: (params) => params.data?.itemUPC
            },
            filters: [],
            vendorItems: null,
            itemsInventories: {},
            itemsAvgLast4Weeks: {},
            dataLoaded: false
        }
    },
    computed:{        
        ...mapGetters('App', ['isMobile']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission']),
        columnDefs(){ 
            const cellRenderer = this.isMobile ? 'dropdownRenderer' : 'clickCallbackRenderer';
            const cellRendererParams = this.isMobile ? {
                openSidePanel: (row) => this.$emit('onProductClick', row.itemUPC),
                canDelete: false
            } : { callback: (row) => this.$emit('onProductClick', row.itemUPC) }
            
            return [
                { hide: this.isMobile, field: 'selected', colId: 'selected', headerName: this.$t(this.selectedColumnLabel), headerTooltip: this.$t(this.selectedColumnLabel), cellRenderer: 'booleanRenderer', filter: AgFilter.SetColumnFilter, filterParams: { valueFormatter: (params) => params.value === 'true' ? this.$t('Button.Yes') : this.$t('Button.No') }, valueGetter: (params) => params?.data?.selected ? params.data.selected.toString() : 'false' },
                { field: 'description', headerName: this.$t('Product.Columns.Description'), headerTooltip: this.$t('Product.Columns.Description'), cellRenderer: cellRenderer, cellRendererParams: cellRendererParams, cellClass: this.isMobile ? "dropdown-cellrenderer" : "", allowedAggFuncs: AgAllowedAggFuncs.Text, filterParams: MultiFilterParams.NormalizeTextFilters },
                { hide: this.isMobile, field: 'itemUPC', sort: '', headerName: this.$t('Product.Columns.ItemUPC'), headerTooltip: this.$t('Product.Columns.ItemUPC'), allowedAggFuncs: AgAllowedAggFuncs.Text, filterParams: MultiFilterParams.NormalizeTextFilters },
                { hide: this.isMobile, field: 'departmentCode', sort: '', headerName: this.$t('Product.Columns.DepartmentCode'), headerTooltip: this.$t('Product.Columns.DepartmentCode'), allowedAggFuncs: AgAllowedAggFuncs.Text, filterParams: MultiFilterParams.NormalizeTextFilters },
                { hide: this.isMobile, field: 'sdpCode', sort: '', headerName: this.$t('Product.Columns.SdpCode'), headerTooltip: this.$t('Product.Columns.SdpCode'), allowedAggFuncs: AgAllowedAggFuncs.Text, filterParams: MultiFilterParams.NormalizeTextFilters },
                { hide: this.isMobile, colId: 'avgLast4Weeks', headerName: this.$t('Inventory.InventoryProduct.AverageLastFourWeeks'), headerTooltip: this.$t('Inventory.InventoryProduct.AverageLastFourWeeks'), valueGetter: this.avgLast4WeeksGetter, filterParams: FilterParams.NumberFilter },
                { hide: this.isMobile || !this.readInventoryPermission, colId: 'inventory', headerName: this.$t('Inventory.InventoryProduct.Inventory'), headerTooltip: this.$t('Inventory.InventoryProduct.Inventory'), valueGetter: this.inventoryQtyGetter, filterParams: FilterParams.NumberFilter },
            ];
        },
        rowData(){
            if(!this.dataLoaded)
                return null

            if(!this.vendorItems)
                return []

                return this.vendorItems.map((p) => {
                    let selected = false;
                    if(this.itemUpcs){
                        selected = this.itemUpcs.includes(p.itemUPC)
                    }

                    return { ...p, selected: selected }
                })
        },
        readInventoryPermission(){
            return this.hasPermission([permissionsDictionary.INVENTORY_READ])
        },
        agTheme() {
            return this.isMobile ? 'ag-theme-material' : 'ag-theme-alpine'
        },
        getGridId() {
            return GridId.PurchaseVendorItems
        },
    },

    watch:{
        itemsAvgLast4Weeks(){
            if(!this.gridOptions.api)
                return

            this.gridOptions.api.refreshCells({
                force: true,
                columns: ['avgLast4Weeks'],
            })
        },
        itemsInventories(){
            if(!this.gridOptions.api)
                return

            this.gridOptions.api.refreshCells({
                force: true,
                columns: ['inventory'],
            })
        }
    },

    methods: {
        ...mapActions('Product', ['fetchProductsAverageLastWeeks']),
        ...mapActions('Inventory', ['fetchProductsInventories']),
        async onGridReady(params) {
            this.gridOptions.api = params.api
            await this.loadData()
        },

        async loadData(){
            this.dataLoaded = false
            await this.loadVendorItems();
            this.dataLoaded = true

            if(!this.vendorItems || this.vendorItems.length === 0)
                return

            this.loadItemsAvgLast4Weeks();
            this.loadItemsInventory();   
        },

        async loadVendorItems(){
            const itemWithCostSearchCriteria = new ItemWithCostSearchCriteria({ vendorCode: this.vendorCode })
            this.vendorItems = await ItemService.getItemsWithCosts(itemWithCostSearchCriteria);
        },

        async loadItemsAvgLast4Weeks(){
            const upcs = [... new Set(this.vendorItems.map(p => p.itemUPC))];
            this.itemsAvgLast4Weeks = await this.fetchProductsAverageLastWeeks({ itemUpcs: upcs, nbOfWeeks: 4 });
        },

        async loadItemsInventory(){
            if(!this.readInventoryPermission)
                return

            const upcs = [... new Set(this.vendorItems.map(p => p.itemUPC))];
            const inventories = await this.fetchProductsInventories(upcs);

            this.itemsInventories = inventories?.reduce((inventories, inventory) => {
                inventories[inventory.itemUpc] = inventory;
                return inventories;
            }, {})
        },

        filtersChanged(filters){
            this.filters = filters;
        },
        inventoryQtyGetter(params) {
            if (params.data && params.node && !params.node.rowPinned) {
                const itemInventory = this.itemsInventories && this.itemsInventories[params.data.itemUPC];
                if(!itemInventory || itemInventory.qty === null)
                    return this.$t('General.NotAvailable')

                return itemInventory.qty
            }
        },
        avgLast4WeeksGetter(params){
            if (params.data && params.node && !params.node.rowPinned) {
                const itemAvgLast4Weeks = this.itemsAvgLast4Weeks && this.itemsAvgLast4Weeks[params.data.itemUPC];
                if(!itemAvgLast4Weeks || itemAvgLast4Weeks.quantity === null)
                    return this.$t('General.NotAvailable')

                return itemAvgLast4Weeks.quantity
            }
        }
    }
}
</script>
