const UOM = {
  EACH: 'ea',
  KG: 'kg',
  LB: 'lb'
}

export class Item {
  constructor() {
    this._ItemID = 0;
    this.itemUPC = null;
    this.itemSKU = null;
    this.description = null;
    this.itemPrice = null;
    this._TargetID = 0;
    this.targetDescription = null;
    this._SdpID = 0;
    this.sdpCode = null;
    this.subDepartmentDescription = null;
    this._DepartmentID = 0;
    this.departmentCode = null;
    this.departmentDescription = null;
    this.isDeleted = null;
    this.brandDescription = null;
    this.formatDescription = null;
    this.statusCode = null;
    this.ageRestricted = 0;
    this.isEbt = 0;
    this.isTaxFreeWithMinQty = 0;
    this.unitOfMeasurement = UOM.EACH
  }
}

  export const convertApiItemToItem = (apiItem) => {
    const item = new Item();
  
    if(!apiItem)
      return item;
  
    item._ItemID = apiItem._ItemID;
    item.itemUPC = apiItem.itemUPC;
    item.itemSKU = apiItem.itemSKU;
    item.description = apiItem.description;
    item.itemPrice = apiItem.itemPrice;
    item._TargetID = apiItem._TargetID;
    item.targetDescription = apiItem.targetDescription;
    item._SdpID = apiItem._SdpID;
    item.sdpCode = apiItem.sdpCode;
    item.subDepartmentDescription = apiItem.subDepartmentDescription;
    item._DepartmentID = apiItem._DepartmentID;
    item.departmentCode = apiItem.departmentCode;
    item.departmentDescription = apiItem.departmentDescription;
    item.isDeleted = apiItem.isDeleted;
    item.brandDescription = apiItem.brandDescription;
    item.formatDescription = apiItem.formatDescription;
    item.statusCode = apiItem.statusCode;
    item.ageRestricted = apiItem.ageRestricted;
    item.isEbt = apiItem.isEbt;
    item.isTaxFreeWithMinQty = apiItem.isTaxFreeWithMinQty;
    item.unitOfMeasurement = apiItem.unitOfMeasurement;

    return item;
  };


export const hasPrice = item => item.itemPrice >= 0
export const isForSale = item => item.statusCode === true
export const withPricePredicate = item => hasPrice(item) && isForSale(item)
export const isWeightItem = item => [UOM.KG.toLocaleLowerCase(), UOM.LB.toLocaleLowerCase()].includes(item.unitOfMeasurement?.toLocaleLowerCase())