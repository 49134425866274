import iframeWs from '@/backoffice/modules/ws-management/components/iframe-ws/iframe-ws.vue'
import wsManagement from '@/backoffice/modules/ws-management/ws-management.vue'
import GeneralLedgerAccount from '@/backoffice/modules/generalLedgerAccount/generalLedgerAccount.vue'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';

const legacyParameters = {  
  name: 'legacyParameters',
  path: '/legacyParameters',
  component: wsManagement,
  meta: {
    sideNav: {
      image: 'fal fa-cog',
      label: 'SideNav.System.Parameters',
      orderIndex: 8
    },
    permissions: ['SystemConfigurationAsRead', 'SystemTaskSchedulerAsRead', 'ReportSystemLogAsRead', 'MenuMonitoringAsRead']
  },
  children: [
    {
      name: 'Settings',
      path: 'Settings',
      component: iframeWs,
      props: { routeName: 'Dashboard.Settings' },
      meta: {
        managementWrap: true,
        sideNav: {
          image: null,
          label: 'SideNav.System.Configs'
        },
        permissions: ['SystemConfigurationAsRead']
      }
    },
    {
      path: 'generalLedgerAccounts',
      name: 'generalLedgerAccounts',
      component: GeneralLedgerAccount,
      meta: {
        requiresAuth: true,
        associatedParentRouteName: null,
        managementWrap: false,
        sideNav: {
          image: null,
          label: 'SideNav.Management.GeneralLedgerAccounts'
        },
        permissions: [permissionsDictionary.GENERAL_LEDGER_ACCOUNT_READ, permissionsDictionary.GENERAL_LEDGER_ACCOUNT_CUD],
        breadcrumb: {
          linkDescription: 'Breadcrumbs.Management.GeneralLedgerAccounts',
          routeNameTo: null,
          explicitTail: null
        }
      }
    },
    {
      name: 'TaskScheduler',
      path: 'TaskScheduler',
      component: iframeWs,
      props: { routeName: 'Dashboard.TaskScheduler' },
      meta: {
        sideNav: {
          image: null,
          label: 'SideNav.System.TaskScheduler'
        },
        permissions: ['SystemTaskSchedulerAsRead']
      }
    },
    {
      name: 'LogExtraction',
      path: 'LogExtraction',
      component: iframeWs,
      props: { routeName: 'Dashboard.LogExtraction' },
      meta: {
        managementWrap: true,
        sideNav: {
          image: null,
          label: 'SideNav.System.LogExtraction'
        },
        permissions: ['ReportSystemLogAsRead']
      }
    },
    {
      name: 'MonitoringStatus',
      path: 'MonitoringStatus',
      component: iframeWs,
      props: { routeName: 'Dashboard.MonitoringStatus' },
      meta: {
        managementWrap: true,
        sideNav: {
          image: null,
          label: 'SideNav.System.MonitoringStatus'
        },
        permissions: ['MenuMonitoringAsRead']
      }
    }
  ]
}

export default legacyParameters;