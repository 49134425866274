<template src="./inventory-product-count.html"></template>

<style lang="scss">
@import './inventory-product-count.scss';
</style>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import ProductInfoHeader from '@/core/components/layout/side-panel/product-info-header/product-info-header.vue';
import ProductCostsBody from '@/core/components/layout/side-panel/product-costs-body/product-costs-body.vue';
import ProductResultFooter from '@/core/components/layout/side-panel/product-result-footer/product-result-footer.vue';
import InventoryScan from '@/backoffice/modules/inventory/components/side-panel/inventory-product-scan/inventory-product-scan.vue';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import * as ItemService from '@/backoffice/modules/item/item.service.js'
import { UnitUOM } from '@/backoffice/modules/inventory/domain/document.js';

export default {
  name: 'inventory-product-template',
  components:{
    ProductInfoHeader,
    ProductCostsBody,
    ProductResultFooter
  },
  props :{
    templatePayload: Object
  },

  data() {
    return {
      blockNewInput: false,
      documentBody: { ...this.templatePayload.documentBody },
      averageLast4WeeksQty: 0,
      productInventory: null,
      displayForm: false
    }
  },

  computed:{
    ...mapGetters('Account', ['hasPermission', 'getCurrentTargetLayerId']),
    ...mapGetters('Inventory', ['getDocument', 'isDocumentOpen']),
    ...mapGetters('Product', ['getEnhancedProduct']),
    isInCreationMode() {
      return !this.documentBody.id;
    },
    displayCostUnitQty() {
      return this.getEnhancedProduct && this.documentBody && this.documentBody.uomCode != 'EA';
    },
    qtyChanged() {
      const qtyChangedInCreation = this.isInCreationMode && !!this.documentBody?.qty
      const qtyChangedInEdition = !this.isInCreationMode && this.documentBody?.qty != this.templatePayload?.documentBody?.qty
      return qtyChangedInCreation || qtyChangedInEdition
    },
    canEditQty() {
      return this.getDocument.canUpdate && this.isDocumentOpen && !this.blockNewInput
    },
    canSave() {
      return this.qtyChanged && this.documentBody.qty && this.documentBody.qty >= 0
    },
    canScan() {
      return this.getDocument.canUpdate && this.isDocumentOpen && this.blockNewInput
    },
    summary() {
      const qty = this.documentBody.qty || 0
      const varianceQty = qty - (this.documentBody.currentInventoryQty || 0)
      const avgCost = this.documentBody.avgUnitCost ? qty * this.documentBody.avgUnitCost : 0
      const varianceCost = this.documentBody.currentInventoryCost ? avgCost - this.documentBody.currentInventoryCost : avgCost
      return {
        qty: qty,
        avgCost: avgCost,
        varianceQty: varianceQty,
        varianceCost: varianceCost
      }
    },  
    readProductPermission(){
      return this.hasPermission([permissionsDictionary.PRODUCT_READ])
    },
    readInventoryPermission(){
      return this.hasPermission([permissionsDictionary.INVENTORY_READ, 
                                 permissionsDictionary.INVENTORY_CUD, 
                                 permissionsDictionary.INVENTORYDOCUMENT_CUD])
    },
    itemUpc() {
      return (this.templatePayload.documentBody && this.templatePayload.documentBody.itemUPC) || this.templatePayload?.item?.itemUPC
    },
    productDefaultAvgUnitCost(){
      if(this.getEnhancedProduct?.productCost?.costValue > 0) 
        return this.getEnhancedProduct.productCost.costValue / (this.getEnhancedProduct.productCost.costUnitQty || 1);

      return 0
    },
    productAvgUnitCost(){
      if(this.productInventory?.averageUnitCost > 0)
        return this.productInventory.averageUnitCost;

      return this.productDefaultAvgUnitCost
    }
  },

  watch: {
    'qtyChanged': {
      handler(newVal){
        this.$sidePanel.isEdit(newVal);
      }
    }
  },

  async mounted () {
    this.setEnhancedProduct(null)
    await this.loadProductInformation()
  },

  methods: {
    ...mapActions('Inventory', ['updateDocument', 'fetchProductInventory']),
    ...mapActions('Product', ['fetchEnhancedProduct', 'fetchProductsAverageLastWeeks']),
    ...mapMutations('Product', { setEnhancedProduct: 'SET_ENHANCED_PRODUCT' }),

    async loadProductInformation() {
      let item = this.templatePayload?.item

      if (!item)
        item = await ItemService.getItemByUpc(this.itemUpc)

      const itemCost = await ItemService.getItemCost(this.itemUpc)
      this.productInventory = await this.fetchProductInventory(this.itemUpc)

      this.setEnhancedProduct({
        ...item,
        productCost: itemCost || {},
        uom: UnitUOM
      })

      if (this.isInCreationMode) {
        this.documentBody = {
          itemUPC: this.itemUpc,
          itemSKU: this.itemUpc,
          avgUnitCost: this.productAvgUnitCost,
          documentID: this.getDocument.id,
          itemID: this.getEnhancedProduct._ItemID,
          itemDescription: this.getEnhancedProduct.description,
          subDepartmentID: this.getEnhancedProduct._SdpID,
          subDepartmentCode: this.getEnhancedProduct.sdpCode,
          subDepartmentDescription: this.getEnhancedProduct.subDepartmentDescription,
          departmentID: this.getEnhancedProduct._DepartmentID,
          departmentCode: this.getEnhancedProduct.departmentCode,
          departmentDescription: this.getEnhancedProduct.departmentDescription,
          uomID: this.getEnhancedProduct.uom.id,
          uomCode: this.getEnhancedProduct.uom.uomCode,
          uomDescription: this.getEnhancedProduct.uom.description,

          currentInventoryQty: this.productInventory.qty ? this.productInventory.qty : 0,
          currentInventoryCost: this.productInventory.qty ? this.productInventory.qty * this.productAvgUnitCost : 0
        }
      }

      this.displayForm = true

      await this.fetchAverage()
    },

    cancel(){
      this.$sidePanel.close();
    },

    scan(){
      this.$sidePanel.show([{ component: InventoryScan }], null, { backgroundIsDisabled: true });
    },

    validateCount(){
      if(this.canSave){
        this.documentBody.qty = parseInt(this.documentBody.qty);
        this.saveProduct();
      }
      else{
        this.$refs.uomCount.focus();
      }
    },

    refreshDocumentBodies(documentBodies){
      documentBodies.forEach(body => {
        if(body.itemUPC === this.itemUpc){
          const qty = body.qty || 0
          const avgCost = body.avgUnitCost ? qty * body.avgUnitCost : 0;
          const currentInventoryQty = this.productInventory.qty ? this.productInventory.qty : 0;
          const currentInventoryCost = this.productInventory.qty ? this.productInventory.qty * this.productAvgUnitCost : 0

          body.currentInventoryQty = currentInventoryQty,
          body.currentInventoryCost = currentInventoryCost

          body.avgCost = avgCost;
          body.varianceQty = qty - (currentInventoryQty || 0);
          body.varianceCost = currentInventoryCost ? avgCost - currentInventoryCost : avgCost;

          body.ignore = false;
        }
      });
    },

    async saveProduct(){
      this.blockNewInput = true;
      const document = cloneDeep(this.getDocument);
      this.documentBody.documentBodyType = this.documentBody.varianceQty > 0 ? 'Declare+' : 'Declare-';

      if(!this.isInCreationMode){
        for (let i = 0; i < document.documentBodies.length; i++) {
          if(document.documentBodies[i].id == this.documentBody.id){
            document.documentBodies[i] = this.documentBody;
            break;
          }
        }
      }
      else{
        document.documentBodies.push(this.documentBody);
      }

      this.refreshDocumentBodies(document.documentBodies)

      try {
        await this.updateDocument(document);
        this.$emit('templatePayload-updated', { documentBody: this.documentBody })
        this.$refs.scan.focus();
      }
      catch (response) {
        if (response.message == "ForceOpen") {

          const result = await prompts.warning({
            html: this.$t(
              'Inventory.Messages.UpdateBlockedForceOpen',
              {
                user: this.getDocument.userName,
                date: moment(this.getDocument.documentLastDatetime).format('yyyy-MM-DD'),
                time: moment(this.getDocument.documentLastDatetime).format('HH:mm')
              })
          });

          if (result.isConfirmed) {
            this.cancel()
          }
        }
        else {
          prompts.error({
              text: this.$t('Inventory.Messages.SaveDocumentError')
          });
        }
      }
    },
    async fetchAverage(){
      const productsAverage = await this.fetchProductsAverageLastWeeks({ itemUpcs: [this.itemUpc], nbOfWeeks: 4 });
      this.averageLast4WeeksQty = productsAverage[this.itemUpc] ? productsAverage[this.itemUpc].quantity : 0
    }
  }
}
</script>
