import GeneralLedgerAccountClient from '@/backoffice/modules/generalLedgerAccount/client/generalLedgerAccount.client'
import { GeneralLedgerAccount, GeneralLedgerAccountDto } from '@/backoffice/modules/generalLedgerAccount/domain/generalLedgerAccount'

export async function getGeneralLedgerAccountsBySection(section) {
    const response = await GeneralLedgerAccountClient.getGeneralLedgerAccountsBySection(section)
    if (response.status !== 200)
        return []

    return response.data.map(generalLedgerAccountDto => new GeneralLedgerAccount(generalLedgerAccountDto))
}

export async function saveGeneralLedgerAccount(generalLedgerAccount) {
    const generalLedgerAccountDto = new GeneralLedgerAccountDto(generalLedgerAccount)
    
    let response = null
    if(generalLedgerAccountDto.generalLedgerAccountId > 0){
        if(generalLedgerAccountDto.gl)
            response = await GeneralLedgerAccountClient.updateGeneralLedgerAccount(generalLedgerAccountDto)
        else{
            await GeneralLedgerAccountClient.deleteGeneralLedgerAccount(generalLedgerAccountDto)
            response = { status: 200, data: { ...generalLedgerAccountDto, generalLedgerAccountId: null } }
        }            
    }
    else
        response = await GeneralLedgerAccountClient.createGeneralLedgerAccount(generalLedgerAccountDto)

    if (response?.status !== 200)
        return

    return new GeneralLedgerAccount(response.data)
}