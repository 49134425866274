import Api, { getNewCancelToken, ApiError } from '@/core/services/_api.config';

const orderApiRoute = '/api/Purchase/Order';

let getOrdersToken = null

export async function getOrder(id) {
    const searchCriteria = encodeURIComponent(JSON.stringify({ orderId: id }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

export async function getOrders(startDate, endDate, maxRows) {
    if (getOrdersToken) {
        getOrdersToken.cancel(ApiError.OPERATION_CANCELED)
        getOrdersToken = null
    }
    
    getOrdersToken = getNewCancelToken()

    const searchCriteria = encodeURIComponent(JSON.stringify({ startDate, endDate, maxRows }))

    const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`, { cancelToken: getOrdersToken.token })
    getOrdersToken = null
        
    if (response.status >= 200 && response.status <= 299) {
        return response.data
    }
    else {
        throw response
    }
}

export async function getOrdersByVendorName(vendorName){
    const searchCriteria = encodeURIComponent(JSON.stringify({ vendorName: vendorName }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

export async function getOrdersByNo(orderNo){
    const searchCriteria = encodeURIComponent(JSON.stringify({ orderNo: orderNo }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

export async function getOrdersByIds(ids){
    const searchCriteria = encodeURIComponent(JSON.stringify({ orderIds: ids }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e.message, data: null };
    }
}


export async function getOrdersByVendorCode(vendorCode) {
    const searchCriteria = encodeURIComponent(JSON.stringify({ vendorCode: vendorCode }))

    try
    {
        const response = await Api().get(`${orderApiRoute}?orderSearchCriteria=${searchCriteria}`);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e.message, data: null };
    }
}

export async function addOrders(order) {
    try
    {
        const response = await Api().post(orderApiRoute, order);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null } ;
    }
}

export async function updateOrders(order) {
    try
    {
        const response = await Api().put(orderApiRoute, order);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

export async function sendOrderByEmailToVendor(payload) {
    try
    {
        const response = await Api().post(`${orderApiRoute}/Send`, payload);
        return { status: response.status, data: response.data };
    }
    catch(e) {
        return { message: e?.message, data: null };
    }
}

export async function getTargetLayerPartiallyReceivedAndPendingOrdersByNotOpenReceptions() {
    try
    {
        const response = await Api().get(`${orderApiRoute}/GetTargetLayerPartiallyReceivedAndPendingOrdersByNotOpenReceptions`);
        return response.data
    }
    catch(e) {
        return { message: e.message, data: null };
    }
}
