<template src="./main.html"></template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import './main.scss';
</style>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import agGridGetters from '@/core/components/common/grid/getters/getters.js';
import { OrderStatus, calculateOrdersProductCount } from '@/backoffice/modules/purchase/domain/purchase.js';
import orderManagement from '@/backoffice/modules/purchase/components/sidePanel/orders/order-management/order-management.vue';
import creationButton from '@/core/components/common/creation-button/creation-button.vue';
import { GridId, AgFilter, AgFilterType, AgAllowedAggFuncs, MultiFilterParams, FilterParams } from '@/core/components/common/grid/grid.const.js'
import grid from '@/core/components/common/grid/persisted-grid.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import sliderList from '@/core/components/common/slider-list/slider-list.vue'
import information from '@/core/components/common/slider-list/templates/information/information.vue'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';

export default {
  mixins: [extractTime],
  name: 'Orders',
  components: {
    searchBar,
    sliderList,
    information,
    grid,
    creationButton,
    periodPicker
  },
  data() {
    return {
      orders: [],
      gridOptions: {
        getRowId: (params) => params.data?.id,
      },
      defaultFilters: {
        orderStatus: {
          filterType: AgFilterType.Set,
          values: [
            OrderStatus.OPEN,
            OrderStatus.PENDING,
            OrderStatus.PARTIALLYRECEIVED
          ]
        },
      },
      period: null,
      periodPickerOptions: [
        {
          name: "RangePicker.this", selected: false, choices: [
            { name: "RangePicker.thisWeek", value: "-0w", selected: false },
            { name: "RangePicker.thisMonth", value: "-0m", selected: false },
            { name: "RangePicker.thisQuarter", value: "-0q", selected: false },
            { name: "RangePicker.thisYear", value: "-0y", selected: false }
          ]
        },
        {
          name: "RangePicker.last", selected: false, choices: [
            { name: "RangePicker.lastWeek", value: "-1w", selected: false },
            { name: "RangePicker.lastMonth", value: "-1m", selected: false },
            { name: "RangePicker.lastQuarter", value: "-1q", selected: false },
            { name: "RangePicker.lastYear", value: "-1y", selected: false }
          ]
        },
        {
          name: "RangePicker.same", selected: false, choices: [
            { name: "RangePicker.sameMonthLastYear", value: "-1ym", selected: false },
            { name: "RangePicker.sameQuarterLastYear", value: "-1yq", selected: false }
          ]
        },
        { name: "RangePicker.custom", dates: "", selected: false, isDateRangePicker: true, value: "" }
      ],
      filters: [],
      displayGrid: false,
      isFetching: false
    }
  },

  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission', 'getUser']),
    ...mapGetters('Grid', ['getUserGridPeriod']),

    userGridPeriod() {
      const userGridPeriod = this.getUserGridPeriod[this.userId]

      if (!userGridPeriod) return null

      const gridPeriod = userGridPeriod[this.getGridId]

      if(!gridPeriod) return null

      const targetLayerState = gridPeriod[this.getCurrentTargetLayerId]

      return targetLayerState || null
    },
    max500Displayed() {
      return this.orders?.length >= 500
    },
    canCreateOrder() {
      return this.hasPermission([permissionsDictionary.ORDER_CUD])
    },
    ordersWithProductCount() {
      return calculateOrdersProductCount(this.orders)
    },
    openOrders() {
      if (!this.ordersWithProductCount)
        return []

      return this.ordersWithProductCount.filter(o => o.orderStatus == OrderStatus.OPEN).sort((left, right) => new Date(right.createdDatetime) - new Date(left.createdDatetime));
    },
    getGridId() {
      return GridId.Orders
    },
    userId() {
      return this.getUser._UserID
    },
    columnDefs() {
      return [
        {
          field: 'orderNO',
          filterParams: FilterParams.NumberFilter,
          sort: 'desc',
          headerName: !this.isMobile ? this.$t('Purchase.Order.Columns.OrderNO') : '#',
          headerTooltip: this.$t('Purchase.Order.Columns.OrderNO'),
          enableRowGroup: false,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          cellRenderer: 'linkRenderer',
          cellRendererParams: (params) => {
            return {
              route: { name: 'order', params: { id: params?.data?.id } }
            }
          }
        },
        { hide: false, field: 'vendorName', headerName: this.$t('Purchase.Order.Columns.VendorName'), headerTooltip: this.$t('Purchase.Order.Columns.VendorName'), valueGetter: (params) => params?.data?.vendorName, filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: false, field: 'createdDate', filter: AgFilter.DateColumnFilter, valueGetter: agGridGetters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.createdDate && new Date(params.data.createdDate), headerName: this.$t('Purchase.Order.Columns.CreatedDate'), headerTooltip: this.$t('Purchase.Order.Columns.CreatedDate') },
        { hide: this.isMobile, field: 'receivedDate', filter: AgFilter.DateColumnFilter, valueGetter: agGridGetters.dateWithMonthNamed, filterValueGetter: (params) => params?.data?.receivedDate && new Date(params.data.receivedDate), headerName: this.$t('Purchase.Order.Columns.ReceivedDate'), headerTooltip: this.$t('Purchase.Order.Columns.ReceivedDate') },
        { hide: false, field: 'orderStatus', filter: AgFilter.SetColumnFilter, filterParams: { valueFormatter: (params) => this.$t(`Purchase.Order.Status.${params.value}`) }, valueGetter: params => { return agGridGetters.valueTranslateGetter(params, 'Purchase.Order.Status'); }, filterValueGetter: (params) => params?.data?.orderStatus, headerName: this.$t('Purchase.Order.Columns.OrderStatus'), headerTooltip: this.$t('Purchase.Order.Columns.OrderStatus'), allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'productCount', filterParams: FilterParams.NumberFilter, headerName: this.$t('Purchase.Order.Columns.OrderBodySums'), headerTooltip: this.$t('Purchase.Order.Columns.OrderBodySums') },
        { hide: this.isMobile, field: 'userUsername', filterParams: MultiFilterParams.NormalizeTextFilters, headerName: this.$t('Purchase.Order.Columns.UserUsername'), headerTooltip: this.$t('Purchase.Order.Columns.UserUsername'), allowedAggFuncs: AgAllowedAggFuncs.Text },
      ]
    }
  },

  watch: {
    getCurrentTargetLayerId() {
      this.init()
    }
  },
  beforeDestroy() {
    this.resetOrders()
  },
  mounted() {
    this.resetOrders()
    this.displayGrid = true

    this.$nextTick(() => {
      this.init()
    })
  },

  methods: {
    ...mapActions('Order', ['getOrders']),
    ...mapMutations('Order', { resetOrders: 'RESET_ORDERS' }),
    ...mapMutations('Grid', { setUserGridPeriod: 'SET_USER_GRID_PERIOD' }),

    onGridReady(params) {
      this.gridApi = params.api
    },
    async init() {
      if (this.period !== (this.userGridPeriod?.periodPicker || '-1q')) {
        this.period = this.userGridPeriod?.periodPicker || '-1q'
        return
      }
      
      const dates = this.convert(this.period)
      
      if (!this.isFetching) {
        await this.fetch({ startDate: dates.start, endDate: dates.end })
      }
    },

    async fetch({ startDate, endDate }) {   
      try {
        this.isFetching = true
        this.resetOrders()
        this.gridApi?.showLoadingOverlay()
        
        this.orders = await this.getOrders({ startDate, endDate, maxRows: 500 });
      }
      catch (error) {
        prompts.error({
          text: this.$t('Purchase.Messages.GetOrdersError')
        });
        this.resetOrders()
      } finally {
        this.isFetching = false
      }
    },

    newOrder() {
      this.$sidePanel.show([{ component: orderManagement }]);
    },
    filtersChanged(filters) {
      this.filters = filters;
    },

    async periodChanged(period) {
      const state = this.userGridPeriod || {}
      state.periodPicker = period

      this.setUserGridPeriod({
        gridId: this.getGridId,
        userId: this.userId,
        targetLayerId: this.getCurrentTargetLayerId,
        gridState: state
      })

      const dates = this.convert(period)
      await this.fetch({ startDate: dates.start, endDate: dates.end })
    }
  }
}
</script>