import wsManagement from '@/backoffice/modules/ws-management/ws-management.vue'
import iframeWs from '@/backoffice/modules/ws-management/components/iframe-ws/iframe-ws.vue'

const legacyOperation = {  
  name: 'legacyOperation',
  path: '/legacyOperation',
  component: wsManagement,
  meta: {
    managementWrap: true,
    sideNav: {
      image: 'fal fa-sack-dollar',
      label: 'SideNav.Operation.Operations',
      orderIndex: 4
    },
    permissions: ['OperationDepositManagementAsRead', 'OperationSearchInvoiceAsRead', 'OperationImportTransactionAsRead']
  },
  children: [
    {
      name: 'DepositManagement',
      path: 'DepositManagement',
      component: iframeWs,
      props: { routeName: 'Dashboard.DepositManagement' },
      meta: {
        managementWrap: true,
        sideNav: {
          image: null,
          label: 'SideNav.Operation.Deposits'
        },
        permissions: ['OperationDepositManagementAsRead']
      }
    },
    {
      name: 'SearchInvoice',
      path: 'SearchInvoice',
      component: iframeWs,
      props: { routeName: 'Dashboard.SearchInvoice' },
      meta: {
        managementWrap: true,
        sideNav: {
          image: null,
          label: 'SideNav.Operation.SearchInvoice'
        },
        permissions: ['OperationSearchInvoiceAsRead']
      }
    },
    {
      name: 'ImportTransaction',
      path: 'ImportTransaction',
      component: iframeWs,
      props: { routeName: 'Dashboard.ImportTransaction' },
      meta: {
        sideNav: {
          image: null,
          label: 'SideNav.Operation.ImportTransactions'
        },
        permissions: ['OperationImportTransactionAsRead']
      }
    }
  ]
}

export default legacyOperation;