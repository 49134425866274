<template src="./general-ledger-report.html"></template>

<script>
import { mapActions, mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue';
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue';
import grid from '@/core/components/common/grid/grid.vue';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import printReport from '@/backoffice/modules/reporting/mixins/print-report/print-report.js';
import reportFilename from '@/backoffice/modules/reporting/mixins/report-filename/get-report-filename.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';

export default {
    name: 'General-Ledger-Report',
    mixins: [extractTime],
    components: {
        searchBar,
        targetDropdown,
        periodPicker,
        grid
    },

    data() {
        return {
            gridReadyPromiseResolver: null,
            targetChangedEventPromiseResolver: null,
            componentInitialized: false,
            filters: null,
            period: '-0w',
            startDate: null,
            endDate: null,
            targetLayerId: null,
            targetDropdownKey: 0,
            generalLedgerData: [],
            gridOptions: {
                sideBar: true,
                rowGroupPanelShow: 'always',
                groupIncludeTotalFooter: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: 'agSetColumnFilter'
                },
                columnDefs: [
                    {
                        field: 'targetLayerDescription',                         
                        headerName: this.$t('GeneralLedgerReport.Columns.TargetLayerDescription'), 
                        headerTooltip: this.$t('GeneralLedgerReport.Columns.TargetLayerDescription'),
                        enableRowGroup: true
                    },
                    {
                        field: 'section',
                        headerName: this.$t('GeneralLedgerReport.Columns.Section'), 
                        headerTooltip: this.$t('GeneralLedgerReport.Columns.Section'),
                        enableRowGroup: true,
                        hide: true
                    },
                    {
                        field: 'gl',                         
                        headerName: this.$t('GeneralLedgerReport.Columns.GLAccount'), 
                        headerTooltip: this.$t('GeneralLedgerReport.Columns.GLAccount'),
                        enableRowGroup: true,
                        sort: 'asc'
                    },
                    {
                        field: 'description',                         
                        headerName: this.$t('GeneralLedgerReport.Columns.Description'), 
                        headerTooltip: this.$t('GeneralLedgerReport.Columns.Description'),
                        enableRowGroup: true
                    },
                    {
                        field: 'debit',                         
                        headerName: this.$t('GeneralLedgerReport.Columns.Debit'), 
                        headerTooltip: this.$t('GeneralLedgerReport.Columns.Debit'),
                        enableValue: true,
                        filter: 'agNumberColumnFilter', 
                        valueFormatter: formatters.currencyFormatter, 
                        aggFunc: 'sum'
                    },
                    {
                        field: 'credit',                         
                        headerName: this.$t('GeneralLedgerReport.Columns.Credit'), 
                        headerTooltip: this.$t('GeneralLedgerReport.Columns.Credit'),
                        enableValue: true,
                        filter: 'agNumberColumnFilter', 
                        valueFormatter: formatters.currencyFormatter, 
                        aggFunc: 'sum'
                    },
                ],
                onGridReady: () => {
                    this.gridReadyPromiseResolver();
                }
            },
            periodPickerOptions : [
                { name : "RangePicker.yesterday", value : "-1d", selected : false },
                { name : "RangePicker.this", selected : false, choices : [
                    { name : "RangePicker.thisWeek", value : "-0w", selected : false },
                    { name : "RangePicker.thisMonth", value : "-0m", selected : false },
                    { name : "RangePicker.thisQuarter", value : "-0q", selected : false },
                    { name : "RangePicker.thisYear", value : "-0y", selected : false }
                    ]
                },
                { name : "RangePicker.last", selected : false, choices : [
                    { name : "RangePicker.lastWeek", value : "-1w", selected : false },
                    { name : "RangePicker.lastMonth", value : "-1m", selected : false },
                    { name : "RangePicker.lastQuarter", value : "-1q", selected : false },
                    { name : "RangePicker.lastYear", value : "-1y", selected : false }
                    ]
                },
                { name : "RangePicker.custom", dates: "", selected : false, isDateRangePicker : true, value: "" }
            ]
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayer','getCurrentTargetLayerId']),
        rowData(){
            return this.generalLedgerData
        }
    },

    watch:{
        getCurrentTargetLayerId(){
            this.targetDropdownKey++;
        }
    },

    created() {
        Promise.all([
            new Promise(resolve => {
                this.gridReadyPromiseResolver = resolve;
            }),
            new Promise(resolve => {
                this.targetChangedEventPromiseResolver = resolve;
            })
        ]).then(async () => {
            await this.fetchGeneralLedgerData();
            this.componentInitialized = true;
        });
    },

    methods: {
        ...mapActions('Reporting', ['getGeneralLedgerReport']),
        async fetchGeneralLedgerData() {
            const dates = this.convert(this.period) 
            this.startDate = dates.start
            this.endDate = dates.end

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                targetLayerId: this.targetLayerId
            }

            this.gridOptions.api.showLoadingOverlay();

            try {
                this.generalLedgerData = await this.getGeneralLedgerReport(request)
            }
            catch (e) {
                prompts.error({
                    text: this.$t('GeneralLedgerReport.Messages.GetGeneralLedgerDataError')
                });
            }

            this.gridOptions.api.hideOverlay();
        },
        onTargetChanged(target) {
            this.targetLayerId = target.targetLayerId;
            if (!this.componentInitialized) {
                this.targetChangedEventPromiseResolver()
            }
            else {
                this.fetchGeneralLedgerData();
            }
        },
        filtersChanged(filters) {
            this.filters = filters;
        },
        periodChanged(period) {
            this.period = period;
            this.fetchGeneralLedgerData();
        },
        onPrint() {
            printReport(this.gridOptions, this.getCurrentTargetLayer, this.convert(this.period), reportFilename(this.$t('GeneralLedgerReport.Title')))
        }
    }
}
</script>