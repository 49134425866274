export const GeneralLedgerAccountSection = {
    SUBDEPARTMENT: 'Subdepartment',
    PAYMENT: 'Payment',
    TAX: 'Tax'
}

export class GeneralLedgerAccount {
    generalLedgerAccountId
    section
    accounting
    code
    description
    gl

    constructor({ generalLedgerAccountId, section, accounting, code, description, gl }) {
        this.generalLedgerAccountId = generalLedgerAccountId
        this.section = section
        this.accounting = accounting
        this.code = code
        this.description = description
        this.gl = gl ?? ""
    }
}

export class GeneralLedgerAccountDto {
    generalLedgerAccountId
    section
    accounting
    code
    description
    gl

    constructor({ generalLedgerAccountId, section, accounting, code, description, gl }) {
        this.generalLedgerAccountId = generalLedgerAccountId
        this.section = section
        this.accounting = accounting
        this.code = code
        this.description = description
        this.gl = gl
    }
}