<template src="./scan-product.html"></template>

<style lang="scss">
    @import './scan-product.scss';
</style>

<script>
import Scan from '@/core/components/layout/side-panel/scan/scan.vue';
import * as ItemService from '@/backoffice/modules/item/item.service.js'
import { withPricePredicate } from '@/backoffice/modules/item/domain/item.js'

export default {
    name: 'scan-product',

    props:{
        icon: String,
        title: String,
        mustIncludeItemPrice: {
            type: Boolean,
            default: false
        },
        isValidPredicate: {
            type: Function,
            default: () => true
        }
    },

    components: {
        Scan: Scan
    },

    data() {
        return {
            invalidProduct: false,
            invalidPredicate: false
        }
    },

    computed: {
        showScan() {
            return !this.invalidProduct && !this.invalidPredicate
        }
    },

    methods: {
        search() {
            this.$emit('onSearchProduct');
        },

        async searchUpc(upc) {
            if (!upc) {
                return;
            }

            const paddedUpc = upc.padStart(13, '0')

            try {
                const item = await ItemService.getItemByUpc(paddedUpc)
                const isWithPrice = withPricePredicate(item)
                const validPrice = (this.mustIncludeItemPrice && !isWithPrice)
                if (!item?._ItemID || validPrice) {
                    this.setInvalidProduct()
                    return
                }
                else if (!this.isValidPredicate(item)) {
                    this.setInvalidPredicate()
                    return
                }
                else
                    this.$emit('onScanProduct', item)
            }
            catch {
                this.setInvalidProduct()
            }
        },

        backToScan() {
            this.invalidProduct = false;
            this.invalidPredicate = false;
        },
        setInvalidProduct() {
            this.invalidProduct = true;
            this.$nextTick(() => 
                this.$refs.backToScan.focus()
            );
        },
        setInvalidPredicate() {
            this.invalidPredicate = true;
            this.$nextTick(() => 
                this.$refs.backToScan.focus()
            );
        },
        cancel() {
            this.$emit('onCancel');
        }
    }
}
</script>