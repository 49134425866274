<template src="./generalLedgerAccount.html"></template>

<style lang="scss">
@import './generalLedgerAccount.scss';
</style>


<script>
import { mapGetters } from 'vuex'
import Grid from '@/core/components/common/grid/grid.vue'
import SearchBar from '@/core/components/common/search-bar/search-bar.vue'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js'
import * as GeneralLedgerAccountServices from '@/backoffice/modules/generalLedgerAccount/services/generalLedgerAccount.services'
import prompts from '@/core/tools/notifications/notifications'
import { GeneralLedgerAccountSection } from '@/backoffice/modules/generalLedgerAccount/domain/generalLedgerAccount'
import numericCellEditor from '@/core/components/common/grid/cellEditors/numericCellEditor.vue';

export default {
    name: 'GeneralLedgerAccount',

    components: {
        Grid,
        SearchBar,
        numericCellEditor
    },
    
    data() {
        return {
            subdepartmentGridOptions: {
                headerHeight: 50,
                rowHeight: 50,
                defaultColDef: {
                    resizable: true,
                    sortable: true
                },
                rowSelection: 'single',
                stopEditingWhenCellsLoseFocus: true,
                onGridReady: () => {
                    this.fetchSubdepartments()
                },
                getRowId: (params) => params?.data?.code
            },
            paymentGridOptions: {
                headerHeight: 50,
                rowHeight: 50,
                defaultColDef: {
                    resizable: true,
                    sortable: true
                },
                rowSelection: 'single',
                stopEditingWhenCellsLoseFocus: true,
                onGridReady: () => {
                    this.fetchPayments()
                },
                getRowId: (params) => params?.data?.code
            },
            taxGridOptions: {
                headerHeight: 50,
                rowHeight: 50,
                defaultColDef: {
                    resizable: true,
                    sortable: true
                },
                rowSelection: 'single',
                stopEditingWhenCellsLoseFocus: true,
                onGridReady: () => {
                    this.fetchTaxes()
                },
                getRowId: (params) => params?.data?.code
            },
            subdepartmentGeneralLedgerAccounts: null,
            paymentGeneralLedgerAccounts: null,
            taxGeneralLedgerAccounts: null,
            subdepartmentFilters: [],
            paymentFilters: [],
            taxFilters: []
        }
    },

    computed: {
        ...mapGetters('Account', ['hasPermission', 'getCurrentTarget', 'getCurrentTargetLayerId']),

        columnDefs() {
            return [
                { field: 'description', headerName: this.$t('GeneralLedgerAccount.Columns.Element'), headerTooltip: this.$t('GeneralLedgerAccount.Columns.Element') },
                { 
                    field: 'gl', 
                    editable: this.canEdit,
                    cellClass: !this.canEdit ? 'uneditable' : '',
                    cellEditor: 'numericCellEditor',
                    cellEditorParams: {
                        maxLength: 10
                    },
                    valueSetter: this.saveGeneralLedgerAccount,
                    headerName: this.$t('GeneralLedgerAccount.Columns.GLAccount'), 
                    headerTooltip: this.$t('GeneralLedgerAccount.Columns.GLAccount'), 
                }
            ]
        },

        subdepartmentRowData() {
            return this.subdepartmentGeneralLedgerAccounts
        },
        paymentRowData() {
            return this.paymentGeneralLedgerAccounts
        },
        taxRowData() {
            return this.taxGeneralLedgerAccounts
        },

        canEdit() {
            return this.hasPermission([permissionsDictionary.GENERAL_LEDGER_ACCOUNT_CUD])
        }
    },
    
    watch:{
        getCurrentTargetLayerId() {
            this.fetchSubdepartments()
            this.fetchPayments()
            this.fetchTaxes()
        }
    },

    methods: {
        subdepartmentFiltersChanged(filters) {
            this.subdepartmentFilters = filters
        },
        paymentFiltersChanged(filters) {
            this.paymentFilters = filters
        },
        taxFiltersChanged(filters) {
            this.taxFilters = filters
        },

        async saveGeneralLedgerAccount(params){
            const generalLedgerAccount = {...params.data, gl: params.newValue}
            let savedGeneralLedgerAccount = null
            try {
                savedGeneralLedgerAccount = await GeneralLedgerAccountServices.saveGeneralLedgerAccount(generalLedgerAccount)
            } catch (error) {                
                prompts.error({
                    text: this.$t('GeneralLedgerAccount.Messages.SaveGeneralLedgerAccountsError')
                })
            } 
            
            if(savedGeneralLedgerAccount){
                params.node.setData(savedGeneralLedgerAccount)

                prompts.toast({
                    icon: 'success',
                    title: this.$t('GeneralLedgerAccount.Messages.SaveGeneralLedgerAccountsSuccess', { gl: savedGeneralLedgerAccount.gl }),
                    position: 'bottom-end'
                });
            }
        },

        async fetchSubdepartments(){
            this.subdepartmentGridOptions.api.showLoadingOverlay()
            this.subdepartmentGeneralLedgerAccounts = null
            
            try {
                this.subdepartmentGeneralLedgerAccounts = await GeneralLedgerAccountServices.getGeneralLedgerAccountsBySection(GeneralLedgerAccountSection.SUBDEPARTMENT)
            } catch (error) {
                this.subdepartmentGeneralLedgerAccounts = []
                prompts.error({
                    text: this.$t('GeneralLedgerAccount.Messages.GetSubdepartmentGeneralLedgerAccountsError')
                })
            }
            finally{
                this.subdepartmentGridOptions.api.hideOverlay()
            }
        },

        async fetchPayments(){
            this.paymentGridOptions.api.showLoadingOverlay()
            this.paymentGeneralLedgerAccounts = null
            
            try {
                this.paymentGeneralLedgerAccounts = await GeneralLedgerAccountServices.getGeneralLedgerAccountsBySection(GeneralLedgerAccountSection.PAYMENT)
            } catch (error) {
                this.paymentGeneralLedgerAccounts = []
                prompts.error({
                    text: this.$t('GeneralLedgerAccount.Messages.GetPaymentGeneralLedgerAccountsError')
                })
            }
            finally{
                this.paymentGridOptions.api.hideOverlay()
            }
        },

        async fetchTaxes(){
            this.taxGridOptions.api.showLoadingOverlay()
            this.taxGeneralLedgerAccounts = null
            
            try {
                this.taxGeneralLedgerAccounts = await GeneralLedgerAccountServices.getGeneralLedgerAccountsBySection(GeneralLedgerAccountSection.TAX)
            } catch (error) {
                this.taxGeneralLedgerAccounts = []
                prompts.error({
                    text: this.$t('GeneralLedgerAccount.Messages.GetTaxGeneralLedgerAccountsError')
                })
            }
            finally{
                this.taxGridOptions.api.hideOverlay()
            }
        }
    }
}
</script>