<template src="./receiving.html"></template>

<style lang="scss">
@import './receiving.scss';
</style>

<script>
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import { ReceivingStatus } from '@/backoffice/modules/purchase/domain/purchase.js';
import cloneDeep from 'lodash/cloneDeep';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import receivingInfo from '@/backoffice/modules/purchase/components/receiving-info/receiving-info.vue';
import receivingProductScan from '@/backoffice/modules/purchase/components/sidePanel/receivings/receiving-product-scan/receiving-product-scan.vue';
import receivingProduct from '@/backoffice/modules/purchase/components/sidePanel/receivings/receiving-product/receiving-product.vue';
import receivingProductVariance from '@/backoffice/modules/purchase/components/sidePanel/receivings/receiving-product-variance/receiving-product-variance.vue';
import lineGroupRenderer from '@/backoffice/modules/purchase/pages/receivings/receiving/receiving-renderer/line-group-renderer.vue';
import { TOGGLE_STATE } from '@/core/components/common/toggle/toggle.const.js';
import { GridId, AgFilter, AgAllowedAggFuncs, FilterParams } from '@/core/components/common/grid/grid.const.js'
import agGridGetters from '@/core/components/common/grid/getters/getters.js';

export default {
  name: 'Receiving',
  props: {
    id: [Number, String]
  },
  components: {
    grid,
    searchBar,
    receivingInfo,
    lineGroupRenderer
  },

  data() {
    return {
      canUserInteractWithUI: true,
      filters: null,
      gridOptions: {
        getRowId: (params) => params.data?.id,
        suppressCellFocus: true,
        groupDisplayType: 'groupRows',
        groupRowRenderer: "lineGroupRenderer",
        groupRowRendererParams: {
          action: this.redirect,
          label: this.$t('Purchase.Receiving.ReceivingDocument.GroupPrefix')
        },
        groupDefaultExpanded: 1
      },
      rowFunctions: {
        isAllReceived: data => data?.qtyReceived !== 0 && (data?.qtyToReceive - data?.qtyReceived) === 0,
        hasPartiallyReceived: data => data?.qtyReceived !== data?.qtyToReceive && data?.qtyReceived !== 0,
        hasCostChanged: data => (data?.unitCost !== data?.costReceived && data?.costReceived !== 0) || (data?.qtyReceived !== 0 && data?.costReceived === 0),
        isClosed: data => data?.receivingStatus === ReceivingStatus.CLOSED,
        totalValue: data => data?.qtyReceived * data?.costReceived,
        getUpdateQtyToReceive: data => {
          const newQty = data?.qtyToReceive - data?.qtyReceived;
          return newQty > 0 ? newQty : 0;
        },
        getReceiveState: data => {
          const isAllReceived = this.rowFunctions.isAllReceived(data);
          const hasCostChanged = this.rowFunctions.hasCostChanged(data);
          const hasPartiallyReceived = this.rowFunctions.hasPartiallyReceived(data);

          if (isAllReceived && !hasCostChanged)
            return TOGGLE_STATE.ON;

          if (hasPartiallyReceived || hasCostChanged)
            return TOGGLE_STATE.INDETERMINATE;

          return TOGGLE_STATE.OFF;
        },
        updateValuesOnToggleChange: (checked, data) => {
          data.qtyReceived = checked ? data.qtyToReceive : 0;
          data.costReceived = data.unitCost;
        }
      },
    }
  },
  computed: {
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('Receiving', ['getReceiving', 'getReceivingRelatedInfos']),
    agTheme() {
      return this.isMobile ? 'ag-theme-material' : 'ag-theme-alpine'
    },
    getGridId(){
      return GridId.Receiving
    },
    receivingNO() {
      return (this.getReceiving && this.getReceiving.receivingNO) || '';
    },
    receiving() {
      const receiving = this.getReceiving || {}
      const bodies = receiving.receivingBodies || [];

      const bodiesById = {}
      const sums = { qty: 0, cost: 0 }
      for (const body of bodies) {
        bodiesById[body.id] = body;
        sums.qty += body.qty
        sums.cost += (body.qty * body.unitCost);
      }

      return {
        status: receiving.receivingStatus? this.$t(`Purchase.Receiving.Status.${receiving.receivingStatus}`):'',
        date: receiving.createdDate,
        username: receiving.userFullName,
        totalQty: sums.qty,
        totalCost: sums.cost,
        bodies,
        bodiesById
      }
    },

    columnDefs(){
      return [
        { 
          hide: true, 
          field: 'orderNumber',
          rowGroup: true,
          lockPinned: true
        },
        { 
          field: 'description', 
          cellRenderer: this.isMobile ? 'detailRenderer' : 'clickCallbackRenderer',
          cellRendererParams: {
            callback: data => { if(!this.isClosed()) this.$sidePanel.show([{ component: receivingProduct }], { itemUPC: data.upc })},
            template: 'ActionAndInformation',
            getInformation: data => data.upc
          },
          cellClass: 'expend-cell',
          width: this.isMobile ? 125 : null,
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Description'),
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Description')
        },
        { 
          hide: this.isMobile, 
          field: 'upc', 
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.ItemUPC'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.ItemUPC') 
        },
        { 
          hide: this.isMobile, 
          field: 'unitQty', 
          filterParams: FilterParams.NumberFilter,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UnitQty'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UnitQty') 
        },
        { 
          hide: this.isMobile, 
          field: 'uom', 
          filter: AgFilter.SetColumnFilter, 
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UOM'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.UOM') 
        },
        { 
          hide: this.isMobile, 
          colId: 'qtyToReceive', 
          valueGetter: params => this.rowFunctions.getUpdateQtyToReceive(params.data), 
          cellClass: 'purchase-cell',
          filterParams: FilterParams.NumberFilter,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.QtyToReceive'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.QtyToReceive')
        },
        { 
          hide: this.isMobile, 
          field: 'unitCost', 
          valueFormatter: formatters.currencyFormatter, 
          cellClass: 'purchase-cell',
          filterParams: FilterParams.NumberFilter,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Cost'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Cost')
        },
        { 
          colId: 'toReceive',
          cellRenderer: 'toggleRenderer',
          cellRendererParams: {
            canUserInteractWithUI: () => this.canUserInteractWithUI && this.isOpen() && this.canUpdate,
            smallToggle: true
          },
          valueGetter: params => this.rowFunctions.getReceiveState(params.data),
          filterValueGetter: (params) => {
            const state = this.rowFunctions.getReceiveState(params.data)
            return this.$t(`Purchase.Receiving.ReceivedState.${state}`)
          },
          valueSetter: params => {
            this.rowFunctions.updateValuesOnToggleChange(params.newValue, params.data)
            return true;
          },
          onCellValueChanged: e => this.saveLine(e.data.id, e.data.qtyReceived, e.data.costReceived),
          cellClass: !this.isMobile ? 'purchase-cell' : undefined,
          width: this.isMobile ? 71 : undefined,
          filter: AgFilter.SetColumnFilter, 
          allowedAggFuncs: AgAllowedAggFuncs.Text,
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Received'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Received')
        },
        { 
          field: 'qtyReceived',
          cellRenderer: 'integerInputRenderer',
          cellRendererParams: {
            canUserInteractWithUI: () => this.canUserInteractWithUI && !this.isClosed(),
            getSecondInfo: data => this.isMobile ? data.qtyToReceive : undefined
          },
          onCellValueChanged: e => this.changeQuantity(e.data.id, e.newValue),
          cellClass: !this.isMobile ? 'purchase-cell' : undefined,
          cellClassRules: {
            'purchase-cell--indeterminate': params => this.rowFunctions.hasPartiallyReceived(params.data)
          },
          filterParams: FilterParams.NumberFilter,
          width: this.isMobile ? 90 : undefined,        
          headerName: this.$t(`Purchase.Receiving.ReceivingDocument.Columns.${this.isMobile ? 'Quantity' : 'QtyReceived'}`), 
          headerTooltip: this.$t(`Purchase.Receiving.ReceivingDocument.Columns.${this.isMobile ? 'Quantity' : 'QtyReceived'}`)
        },
        { 
          field: 'costReceived',
          cellRenderer: 'currencyInputRenderer',
          cellRendererParams: {
            canUserInteractWithUI: () => this.canUserInteractWithUI && !this.isClosed(),
            getSecondInfo: data => this.isMobile ? data.unitCost : undefined,
            decimalConfigs: {
              locale: this.$i18n.locale,
              currency: null,
              distractionFree: false,
              precision: 2
            }
          },
          onCellValueChanged: e => this.changeCost(e.data.id, e.newValue),
          cellClass: !this.isMobile ? 'purchase-cell' : undefined,
          cellClassRules: {
            'purchase-cell--indeterminate': params => this.rowFunctions.hasCostChanged(params.data)
          },
          filterParams: FilterParams.NumberFilter,
          width: this.isMobile ? 90 : undefined,       
          headerName: this.$t(`Purchase.Receiving.ReceivingDocument.Columns.${this.isMobile ? 'Cost' : 'CostReceived'}`), 
          headerTooltip: this.$t(`Purchase.Receiving.ReceivingDocument.Columns.${this.isMobile ? 'Cost' : 'CostReceived'}`)
        },
        { 
          hide: this.isMobile, 
          field: 'reason',
          cellClass: 'purchase-cell',
          cellClassRules: {
            'purchase-cell--indeterminate': params => this.rowFunctions.hasPartiallyReceived(params.data) || this.rowFunctions.hasCostChanged(params.data),
          },        
          filter: AgFilter.SetColumnFilter, 
          allowedAggFuncs: AgAllowedAggFuncs.Text,  
          headerName: this.$t('Document.Columns.ReasonCode'), 
          headerTooltip: this.$t('Document.Columns.ReasonCode')
        },
        { 
          hide: this.isMobile,
          field: 'total',
          valueGetter: params => this.rowFunctions.totalValue(params.data),
          valueFormatter: formatters.currencyFormatter, 
          cellClassRules: {
            'purchase-cell--indeterminate': params => this.rowFunctions.hasPartiallyReceived(params.data) || this.rowFunctions.hasCostChanged(params.data),
          },   
          filterParams: FilterParams.NumberFilter,       
          headerName: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Total'), 
          headerTooltip: this.$t('Purchase.Receiving.ReceivingDocument.Columns.Total')
        }
      ]
    },

    gridData() {
      return this.receiving.bodies.map(b => {
        const orderInfos = this.getReceivingRelatedInfos[b.orderBodyID] || {};
        const qtyOrdered = orderInfos.qtyOrdered || 0; 
        const qtyToReceive = qtyOrdered - (orderInfos.qtyReceivedInOtherReceiving || 0);
        const unitCostOrdered = orderInfos.unitCostOrdered || 0; 

        return {
          id: b.id,
          orderNumber: orderInfos.orderNumber,
          description: b.purchaseBodyInfo?.itemDescription,
          upc: b.purchaseBodyInfo?.itemUPC,
          unitQty: b.unitQty,
          uom: b.purchaseBodyInfo?.uomDescription,
          qtyToReceive: qtyToReceive > 0 ? qtyToReceive : 0,
          unitCost: unitCostOrdered,
          qtyReceived: b.qty,
          costReceived: b.unitCost,
          reason: b.reason
        }
      });
    },
    canReceiveAll() {
      if(this.isClosed() || !this.showActions)
        return false;
      return this.gridData.some(data => data.qtyToReceive != 0)
    },
    isReadOnly() {
      if (!this.getReceiving)
        return true;

      if(this.isClosed())
        return true;

      return !this.getReceiving.canUpdate || this.getReceiving.receivingStatus !== ReceivingStatus.OPEN;
    },
    showActions(){
      if (!this.getReceiving)
        return false;

      return this.getReceiving.receivingStatus !== ReceivingStatus.VOID;
    },
    canUpdate(){
      return this.getReceiving?.canUpdate
    }
  },
  watch: {
    '$route': 'loadData',
    isMobile: {
      'handler'() {
        this.setColumnDefs();

        if (this.gridOptions.api && this.gridOptions.columnApi) {
          this.gridOptions.columnApi.applyColumnState({ state: this.gridOptions.columnDefs });
        }
      },
      immediate: false
    }
  },

  beforeDestroy(){
    this.resetRelatedReceivings()
    this.resetReceiving()
    this.resetRelatedOrders()
  },

  methods: {
    ...mapActions('Receiving', ['fetchReceiving', 'fetchRelatedOrders', 'fetchRelatedReceivings', 'saveReceiving']),
    ...mapMutations('Breadcrumb', {setBreadcrumbContext: 'SET_CONTEXT'}),
    ...mapMutations('Receiving',{resetRelatedReceivings: 'RESET_RELATED_RECEIVINGS', resetRelatedOrders:'RESET_RELATED_ORDERS', resetReceiving:'RESET_RECEIVING'}),
    isClosed(){
      return this.getReceiving && this.getReceiving.receivingStatus === ReceivingStatus.CLOSED;
    },
    isOpen(){
      return this.getReceiving && this.getReceiving.receivingStatus === ReceivingStatus.OPEN;
    },

    async onGridReady(params) {
      await this.loadData()
      this.gridOptions.api = params.api
    },

    async loadData() {
      try {
        await this.fetchReceiving(Number.parseInt(this.id))
      }
      catch(error) {
        const codeTranslate = error?.status === 403 ? 'Purchase.Messages.ForbiddenAction' : 'Purchase.Messages.GetReceivingError'
        prompts.error({
          text: this.$t(codeTranslate)
        });
      }

      this.setBreadcrumbContext({ receiving: this.getReceiving });

      this.fetchRelatedOrders(this.getReceiving);
      const orderIds = [...new Set((this.getReceiving.receivingBodies || []).map(b => b.orderID))];
      this.fetchRelatedReceivings({ receivingId: this.getReceiving.id, orderIds: orderIds });
    },
    async receiveAll() {
      const allUpdatedLines = this.gridData.reduce((agg, row) => {
        const body = this.receiving.bodiesById[row.id];
        const qty = row.qtyToReceive;
        const unitCost = body.unitCost ? body.unitCost : row.unitCost
        agg[row.id] = {
          ...body,
          qty: qty,
          unitCost: unitCost,
          cost: qty * unitCost
        }
        return agg
      }, {})

      await this.save(allUpdatedLines);
    },
    async saveLine(id, quantityReceived, costReceived) {
      const updatedBody = {
        [id]: {
          ...this.receiving.bodiesById[id],
          qty: quantityReceived,
          unitCost: costReceived,
          cost: costReceived * quantityReceived
        }
      }

      await this.save(updatedBody);
    },
    async changeQuantity(id, quantityReceived) {
      if (quantityReceived < 0)
        quantityReceived = 0
      
      const updatedBody = {
        [id]: {
          ...this.receiving.bodiesById[id],
        qty: quantityReceived,
        cost: this.receiving.bodiesById[id].unitCost * quantityReceived
        }
      }

      await this.save(updatedBody);
    },
    async changeCost(id, costReceived) {
      const updatedBody = {
        [id]: {
          ...this.receiving.bodiesById[id],
          unitCost: costReceived,
          cost: costReceived * this.receiving.bodiesById[id].qty
        }
      }

      await this.save(updatedBody);
    },
    async save(updatedBodies) {
      this.canUserInteractWithUI = false

      const updatedReceiving = {
        ...this.getReceiving,
        receivingBodies: this.getReceiving.receivingBodies.map(originalBody => {
          const updatedBody = updatedBodies[originalBody.id];
          if (updatedBody) {
            updatedBody.ignore = false;
            return updatedBody
          }
          return originalBody;
        })
      }

      try {
        await this.saveReceiving(updatedReceiving);
      } catch (error) {
        const codeTranslate = error?.status===403?'Purchase.Messages.ForbiddenAction':'Purchase.Messages.SaveReceivingError'
        this.fetchReceiving(this.id)
        await  prompts.error({
          text: this.$t(codeTranslate)
        });
      }
      finally {
        this.canUserInteractWithUI = true;
      }
    },
    async closeReceiving(){  
      this.canUserInteractWithUI = false
      await this.$sidePanel.show([{ component: receivingProductVariance }]);
      this.canUserInteractWithUI = true
    },
    async voidReceiving() {
      const result = await prompts.warning({
        html: this.$t('Purchase.Messages.VoidReceiving')
      });

      if (!result.isConfirmed)
        return;

      this.canUserInteractWithUI = false
      const receiving = cloneDeep(this.getReceiving);
      receiving.receivingStatus = ReceivingStatus.VOID;

      try{
        await this.saveReceiving(receiving);
      }
      catch(error){
        const codeTranslate = error?.status===403?'Purchase.Messages.ForbiddenAction':'Purchase.Messages.VoidReceivingError'
        await prompts.error({
            text: this.$t(codeTranslate)
        });
      }
    },
    scanProduct() {
      this.$refs.receivingProductScan.blur();
      this.$sidePanel.show([{ component: receivingProductScan }], null, { backgroundIsDisabled: true });
    },
    filtersChanged(filters){
      this.filters = filters;
    },
    redirect(orderNo){
      const key = Object.keys(this.getReceivingRelatedInfos).find(x=> this.getReceivingRelatedInfos[x].orderNumber == orderNo);
      this.$router.push({ name: 'order' , params: { id:this.getReceivingRelatedInfos[key].orderId }});
    }
  }
}
</script>