export default {
    namespaced: true,
    name: 'Grid',
    state: {
        userGridState: {},
        userGridPeriod: {}
    },
    getters: {
        getUserGridPeriod: state => {
            return state.userGridPeriod
        },
        getUserGridState: state => {
            return state.userGridState
        }
    },
    mutations: {
        SET_USER_GRID_PERIOD(state, { gridId, userId, gridState, targetLayerId }) {
            if(!state.userGridPeriod[userId]){
                state.userGridPeriod[userId] = {}
            }
            if(!state.userGridPeriod[userId][gridId]){
                state.userGridPeriod[userId][gridId] = {}
            }
            state.userGridPeriod[userId][gridId][targetLayerId] = gridState
        },
        SET_USER_GRID_STATE(state, { gridId, userId, gridState, targetLayerId }) {
            if(!state.userGridState[userId]){
                state.userGridState[userId] = {}
            }
            if(!state.userGridState[userId][gridId]){
                state.userGridState[userId][gridId] = {}
            }
            state.userGridState[userId][gridId][targetLayerId] = gridState
        },
        REMOVE_USER_GRID_PERIOD(state, { gridId, userId, targetLayerId }) {
            if (state.userGridPeriod[userId] && state.userGridPeriod[userId][gridId] && state.userGridPeriod[userId][gridId][targetLayerId]) {
                state.userGridPeriod[userId][gridId][targetLayerId] = null
            }
        },
        REMOVE_USER_GRID_STATE(state, { gridId, userId, targetLayerId }) {
            if (state.userGridState[userId] && state.userGridState[userId][gridId] && state.userGridState[userId][gridId][targetLayerId]) {
                state.userGridState[userId][gridId][targetLayerId] = null
            }
        }
    }
} 