import api from '@/core/services/_api.config'

const url = '/api/generalLedgerAccount'

export default {
    getGeneralLedgerAccountsBySection: async (section) => {
        return await api()
                    .get(`${url}?section=${section}`)
                    .catch((error) => {
                        throw error.response
                    })
    },
    createGeneralLedgerAccount: async (generalLedgerAccountDto) => {
        return await api()
                    .post(url, generalLedgerAccountDto)
                    .catch((error) => {
                        throw error.response
                    })
    },
    updateGeneralLedgerAccount: async (generalLedgerAccountDto) => {
        return await api()
                    .put(url, generalLedgerAccountDto)
                    .catch((error) => {
                        throw error.response
                    })
    },
    deleteGeneralLedgerAccount: async (generalLedgerAccountDto) => {
        return await api()
                    .delete(url, { data: generalLedgerAccountDto } )
                    .catch((error) => {
                        throw error.response
                    })
    },
}