<template src="./promotion-product.html"></template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import promotionInfo from '@/backoffice/modules/promotion/components/promotion-info/promotion-info.vue';
import promotionProductAdd from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-add/promotion-product-add.vue';
import promotionProductScan from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-scan/promotion-product-scan.vue';
import collapse from '@/core/components/common/collapse/collapse.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import getters from '@/core/components/common/grid/getters/getters.js';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import { calculateDiscountedPrice, DiscountTypeCode, PromotionItemCategory } from '@/backoffice/modules/promotion/domain/promotion.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import { GridId, MultiFilterParams, FilterParams, AgAllowedAggFuncs } from '@/core/components/common/grid/grid.const.js'
import lineGroupRenderer from '@/backoffice/modules/purchase/pages/receivings/receiving/receiving-renderer/line-group-renderer.vue';

export default {
    name: 'promotion-product',
    props:{
      id: [String, Number]
    },
    components: {
      promotionInfo,
      collapse,
      grid,
      searchBar,
      lineGroupRenderer
    },
    data() {
      return {
        showActions: true,
        isReadOnly: false,
        gridOptions: {
          getRowId: (params) => params?.data?.id,
          initialGroupOrderComparator: this.compareItemCategories,
          groupDefaultExpanded: 1,
          groupDisplayType: 'groupRows',
          groupRowRenderer: "lineGroupRenderer"
        },
        filters: [],
        averagesLastWeeks: null,
        productsInventories: null
      };
    },
    computed:{
      ...mapGetters('App', ['isMobile']),
      ...mapGetters('Promotion', ['getPromotionDiscount', 'getPromotion', 'getPromotionItems']),
      ...mapGetters('Account', ['hasPermission', 'getCurrentTargetId']),

      agTheme() {
        return this.isMobile ? 'ag-theme-material' : 'ag-theme-alpine'
      },
      getGridId() {
        return GridId.Promotion
      },
      isSelectedTargetLayer(){
        if(!this.getPromotion)
          return false

        return this.getPromotion.targetId  === this.getCurrentTargetId
      },
      readInventoryPermission(){
        return this.hasPermission([permissionsDictionary.INVENTORY_READ])
      },
      isBxGy(){
        return this.getPromotionDiscount?.isBxGy
      },
      columnDefs() {
        const descriptionCellRenderer = this.isMobile ? 'dropdownRenderer' : 'clickCallbackRenderer';
        const descriptionCellRendererParams = this.isMobile ? (params) => {
            return{
              openSidePanel: this.openItemInfo, 
              canDelete: () => params?.data?.canDelete,
              onDelete: ()=> this.deletePromotionItemPrompt(params?.data)
            }
        } : { callback: this.openItemInfo }

        const upcCellRenderer = this.isMobile ? null : 'menuRenderer';
        const upcCellRendererParams = this.isMobile ? null : (params) => {
          return {
            canUpdate: () => this.isSelectedTargetLayer,
            canDelete: () => this.isSelectedTargetLayer,
            editEntity: () => this.openItemInfo(params?.data),
            delete: () => this.deletePromotionItemPrompt(params?.data)
          }
        }

        const regularDefs = [
          { hide: this.isMobile, field: 'upc', filterParams: MultiFilterParams.NormalizeTextFilters, headerName: this.$t('PromotionItem.Columns.UPC'), headerTooltip: this.$t('PromotionItem.Columns.UPC'), cellClass:'ag-cell-overflow', cellRenderer: upcCellRenderer, cellRendererParams: upcCellRendererParams, enableRowGroup: false },
          { field: 'description', filterParams: MultiFilterParams.NormalizeTextFilters, headerName: this.$t('PromotionItem.Columns.Description'), headerTooltip: this.$t('PromotionItem.Columns.Description'), cellRenderer: descriptionCellRenderer, cellRendererParams: descriptionCellRendererParams, cellClass: this.isMobile ? "dropdown-cellrenderer" : "", valueGetter: getters.description, allowedAggFuncs: AgAllowedAggFuncs.Text, enableRowGroup: false },
          { hide: this.isMobile || !this.readInventoryPermission, field: 'inventory', filterParams: FilterParams.NumberFilter, headerName: this.$t('PromotionItem.Columns.Inventory'), headerTooltip: this.$t('PromotionItem.Columns.Inventory'), valueGetter: this.inventoryQtyGetter, enableRowGroup: false },
          { hide: this.isMobile || !this.readInventoryPermission, field: 'averageUnitCost', filterParams: FilterParams.NumberFilter, headerName: this.$t('PromotionItem.Columns.AverageUnitCost'), headerTooltip: this.$t('PromotionItem.Columns.AverageUnitCost'), valueGetter: this.inventoryAverageUnitCostGetter, valueFormatter: formatters.currencyFormatter, enableRowGroup: false },
          { hide: this.isMobile, field: 'averageLast4WeeksQty', filterParams: FilterParams.NumberFilter, headerName: this.$t('PromotionItem.Columns.AverageLast4WeeksQty'), headerTooltip: this.$t('PromotionItem.Columns.AverageLast4WeeksQty'), valueGetter: this.averageLastWeeksGetter, enableRowGroup: false },
          { hide: this.isMobile, field: 'regularPrice', filterParams: FilterParams.NumberFilter, headerName: this.$t('PromotionItem.Columns.RegularPrice'), headerTooltip: this.$t('PromotionItem.Columns.RegularPrice'), valueFormatter: formatters.currencyFormatter, enableRowGroup: false },
          { hide: this.isMobile, field: 'discountedPrice', filterParams: FilterParams.NumberFilter, headerName: this.$t('PromotionItem.Columns.DiscountedPrice'), headerTooltip: this.$t('PromotionItem.Columns.DiscountedPrice'), valueFormatter: this.promotionItemFormatter, enableRowGroup: false },
        ];

        if(this.isBxGy){
          regularDefs.unshift(
            { 
              hide: true,
              field: 'category',
              rowGroup: true,
              valueGetter: params => this.$t(`Promotion.ItemCategory.${params.data.category}`),
              rowGroupIndex: 0
            }
          )
        }

        return regularDefs
      },
      rowData() {
        if(this.getPromotionItems?.length === 0)
          return []

        return this.getPromotionItems.map( i => ({...i, discountedPrice: calculateDiscountedPrice(this.getPromotionDiscount, i.regularPrice)}));
      }
    },
    watch:{
      'rowData.length':{
        handler(newValue, oldValue){
          if(newValue > oldValue){
            this.loadProductsInformations()
          }
        }
      },
      getCurrentTargetId(newVal, oldVal){
        if(newVal && newVal != oldVal){
          this.$router.push({ name: this.$route.meta.associatedParentRouteName });
        }
      }
    },

    beforeDestroy(){
      this.SET_PROMOTION({})
    },

    methods: {
      ...mapActions('Promotion', ['fetchPromotion', 'deletePromotionItem']),
      ...mapActions('Product', ['fetchProductsAverageLastWeeks']),
      ...mapActions('Inventory', ['fetchProductsInventories']),
      ...mapMutations('Promotion', ['SET_PROMOTION']),

      promotionItemFormatter(params){
        if(params.node?.data?.category === PromotionItemCategory.BuyX){
          return " - "
        }
        return formatters.currencyFormatter(params)
      },

      compareItemCategories(params){
        return params.nodeA.key === this.$t(`Promotion.ItemCategory.${PromotionItemCategory.BuyX}`) ? -1 : 1
      },
      onGridReady(params) {
        this.gridOptions.api = params.api
        this.init()
      },
      async init(){
        await this.loadPromotions()
        await this.loadProductsInformations()

        if (!this.rowData?.some(i => !!i)) {
          if (this.isBxGy) {
            this.addItemToBuy()
          }
          else {
            this.addItem()
          }
        }
      },

      async loadPromotions(){

        try{
          await this.fetchPromotion(parseInt(this.id))
        }
        catch(e){
          prompts.error({
              text: this.$t('Promotion.Messages.FetchPromotionError')
          });
        }

      },

      async loadProductsInformations() {
        const upcs = [... new Set(this.getPromotionItems.map(item => item.upc))]

        if (upcs.length === 0)
          return

        this.averagesLastWeeks = await this.fetchProductsAverageLastWeeks({ itemUpcs: upcs, nbOfWeeks: 4 })

        if(this.readInventoryPermission){
          const inventories = await this.fetchProductsInventories(upcs)

          this.productsInventories = inventories.reduce((inventories, inventory) => {
            inventories[inventory.itemUpc] = inventory;
            return inventories;
          }, {})
        }

        setTimeout(() => {
            this.gridOptions.api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: ['averageLast4WeeksQty', 'inventory', 'averageUnitCost'],
          })
        });
      },
      addItem(){
        this.$sidePanel.show([{ component: promotionProductScan }], {category: PromotionItemCategory.GetY}, { backgroundIsDisabled: true })
      },
      addItemToBuy(){
        this.$sidePanel.show([{ component: promotionProductScan }], {category: PromotionItemCategory.BuyX}, { backgroundIsDisabled: true })
      },
      openItemInfo(item) {
        this.$sidePanel.show([{ component: promotionProductAdd }], { itemUPC: item.upc, category: item.category });
      },
      async deletePromotionItemPrompt(item) {
        const result = await prompts.warning({html: this.$t('Promotion.Messages.DeletePromotionItemPrompt')});
        if (result.isConfirmed) {
          try{
            await this.deletePromotionItem(item.id);
          }
          catch(error){
            prompts.error({
              text: this.$t('Promotion.Messages.DeletePromotionItemError')
            });
          }
        }
      },
      filtersChanged(filters){
        this.filters = filters
      },
      averageLastWeeksGetter(params) {
        const averageLastWeeks = this.averagesLastWeeks && this.averagesLastWeeks[params?.data?.upc]
        return (averageLastWeeks && averageLastWeeks.quantity) || 0
      },
      inventoryQtyGetter(params) {
        const productInventory = this.productsInventories && this.productsInventories[params?.data?.upc];
        if(!productInventory || productInventory.qty === null)
          return this.$t('General.NotAvailable')

        return productInventory.qty
      },
      inventoryAverageUnitCostGetter(params) {
        const productInventory = this.productsInventories && this.productsInventories[params?.data?.upc];
        if(!productInventory || productInventory.averageUnitCost === null)
          return this.$t('General.NotAvailable')

        return productInventory.averageUnitCost
      }
    }
}
</script>