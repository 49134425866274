<template src="./product-info-header.html"></template>

<style lang="scss">
    @import './product-info-header.scss';
</style>

<script>
import tooltip from '@/core/components/common/tooltip/tooltip.vue'
import { isWeightItem } from '@/backoffice/modules/item/domain/item';

const emptyProduct = {
    description: null,
    brandDescription: null,
    itemUPC: null,
    format: null,
    departmentCode: null,
    departmentDescription: null,
    sdpCode: null,
    subDepartmentDescription: null,
}
export default {
    name: 'product-info-header',
    props :{
        product: {
            type: Object,
            default: function () {
                return emptyProduct;
            }
        },
        inventory: {
            type: Number,
            default: 0
        },
        readProductPermission: {
            type: Boolean,
            default: false
        },
        readInventoryPermission: {
            type: Boolean,
            default: false
        },
        averageLastFourWeeks: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            averageLastFourWeeksTooltip: 'Inventory.InventoryProduct.AverageTooltip'
        }
    },
    components:{
        tooltip
    },
    computed: {
        info() {
            return this.product || emptyProduct;
        }
    },
    methods:{
        getInfoFormatDescription(){
            return isWeightItem(this.product) ? this.$t('Promotion.AddProduct.SoldByWeight') : this.info.formatDescription;
        }
    }
}
</script>