<template src="./search-list.html"></template>

<style lang="scss">
    @import './search-list.scss';
</style>

<script>
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import checkbox from '@/core/components/common/checkbox/checkbox.vue';
import toggle from '@/core/components/common/toggle/toggle.vue';
import scan from '@/core/components/layout/side-panel/scan/scan.vue';
import spinLoader from '@/core/components/common/spin-loader/spin-loader.vue';

export default {
    name: 'search-list',

    components: {
        searchBar,
        checkbox,
        toggle,
        scan,
        spinLoader
    },

    model: {
        prop: 'selected'
    },

    props: {
        placeholder: {
            type: String,
            default: ''
        },
        selected: {
            type: [Object, Array],
            default: null
        },
        elements: Array,
        isMultiSelect: Boolean,
        trackBy:String,
        autoFocus: Boolean,
        autocomplete: {
            type: String,
            default: 'on'
        },
        loading: Boolean,
        searchBar: {
            type: Boolean,
            default: true
        },
        isAddToListVisible: {
            type: Boolean,
            default: false
        },
        isSelectAllVisible: {
            type: Boolean,
            default: true
        },
        btnAddToListText:{
            type: String,
            default: 'General.Buttons.AddToList'
        },
        searchScope: {
            type: Boolean,
            default: false
        },
        searchBarDisabled: {
            type: Boolean,
            default: false
        }, 
        scan: {
            type: Boolean,
            default: false
        },
        scanLabel: String,
        isSelectablePredicate: {
            type: Function,
            default: () => true
        }
    },

    data() {
        return {
            filters: [],
            scanValue: null,
            selectedItems: [],
            activatedScan: this.scan,
            searchBarKey: 0
        };
    },
    watch:{
        elements:{
            handler(){
                this.selectedItems.splice(0, this.selectedItems.length)
            }
        },
        selectedItems: {
            handler(newVal){
                this.$emit('selectionChanged',newVal)
            }
        }
    },
    computed: {
        showNoResultMessage() {
            return (this.filters.length > 0 || this.scanValue) && this.formattedElements.length == 0;
        },
        formattedElements() {
            if (!this.elements) {
                return [];
            }

            if (!this.isMultiSelect) {
                return this.elements.map(element => { return { data: element }; });
            }

            return this.elements.map(element => {
                return { 
                    data: element,
                    checked: (this.selected && this.selected.some(sl => sl === element))|| this.elementChecked(element)|| false
                };
            });
        },
        canAddToList(){
            return this.selectedItems.length > 0
        },
        canSelectAll(){
            return this.elements?.length > 0
        },
        btnSelectAllText (){
            return this.btnSelectAllState ? 'General.Buttons.SelectAll':'General.Buttons.UnselectAll'
        },
        btnSelectAllState(){
            const selectedLength = this.selected?.length ?? 0
            const elementsLength = this.elements?.length ?? 0
            return selectedLength < elementsLength
        },
        enabledScan(){
            return this.scan && this.activatedScan
        }
    },

    methods: {
        elementChecked(element){
            return element[this.trackBy]? this.selectedItems.some(elt => elt[this.trackBy] == element[this.trackBy]) || (this.selected && this.selected.some(sl=>(sl[this.trackBy] && sl[this.trackBy]==element[this.trackBy])))
                                            : this.selectedItems.some(elt => elt === element)
        },
        select(element) {
            if (!this.isSelectablePredicate(element.data))
                return
            
            if (!this.isMultiSelect) {
                this.$emit('input', element.data);
            }else{
                this.addToSelectedItemsToggle(element.data)
            }
        },
        filtersChanged(filters, scope) {
            this.filters = filters;
            if(scope !== undefined){
                this.$emit('search', this.filters, scope);
            }
            else{
                this.$emit('search', this.filters)
            }
            
        },

        saveSelectedItems(){
            this.$emit('addToList',this.selectedItems)
        },

        addToSelectedItemsToggle(element){
            const index = this.selectedItems.findIndex(elt =>elt === element)
            if(index == -1){
                this.selectedItems.push(element)
            }else{
                this.selectedItems = this.selectedItems.filter(elt => elt !== element )
            }
        },
        selectAllToggle(){
            if(!this.btnSelectAllState){
                this.selectedItems = []
            }else{
                this.elements.forEach(element => {
                    const index = this.selectedItems.findIndex(elt =>elt === element)
                    if(index == -1){
                        this.selectedItems.push(element)
                    }
                });
            }
        },
        onScanToggle(toggle){
            this.activatedScan = toggle;            
            if(toggle)
                this.searchBarKey++;
        },
        onScan(value) {
            this.scanValue = value;
            this.$emit('onScan', value)
        },
        onSearchInputFocus(){
            this.activatedScan = false;
            this.scanValue = null;
        },
        resetSearchAndScan(){
            this.scanValue = null;
            this.filters = [];
        }
    }
}
</script>