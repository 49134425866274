<template src="./promotion-product-search.html"></template>

<script>
import { mapGetters, mapMutations} from 'vuex';
import promotionProductScan from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-scan/promotion-product-scan.vue';
import prompts from '@/core/tools/notifications/notifications';
import { promotionClient } from '@/backoffice/modules/promotion/services/promotion.client.js'
import ProductSearch from '@/core/components/layout/side-panel/product-search/product-search.vue'
import SearchError from '@/backoffice/modules/purchase/components/sidePanel/orders/scan-search-error/error.vue'
import { cloneDeep } from 'lodash';
import { PromotionItemCategory } from '@/backoffice/modules/promotion/domain/promotion';
import { isWeightItem } from '@/backoffice/modules/item/domain/item'
import { WeightDiscountTypeCode } from '@/backoffice/modules/promotion/domain/promotion';

export default {
    name: 'promotion-product-search',

    props: {
    templatePayload: {
      type: Object,
      default: function(){
        return { category: PromotionItemCategory.GetY}
      }
    }
  },

    components: {
        ProductSearch,
        SearchError
    },
    computed:{
        ...mapGetters('Promotion', ['getPromotionItems', 'getPromotionDiscount', 'getPromotion']),
        canSave(){
            return this.selectedItems.length > 0 && !this.saving
        },
        discount(){
            return this.getPromotionDiscount
        },
        existingItems(){
            if(!this.getPromotion){
                return []
            }
            return this.getPromotion
                .getItemsByCategory(this.templatePayload?.category)
                .map(elt => ({ itemUPC: elt.upc }))
        },
        itemCategory(){
            if(!this.templatePayload?.category || !this.getPromotion?.discount?.isBxGy){
                return ''
            }
            return this.$t(`Promotion.ItemCategory.${this.templatePayload.category}`)
        }
    },

    data() {
        return {
            invalid: false,
            saving: false,
            selectedItems: []
        }
    },

    methods: {
        ...mapMutations("Promotion", {addPromotionItems: 'ADD_PROMOTION_ITEMS', setPromotion: 'SET_PROMOTION'}),        
        isValidItemPredicate(item) {
            return !isWeightItem(item) || (isWeightItem(item) && WeightDiscountTypeCode.has(this.discount?.typeCode))
        },
        cancel(){
            this.$sidePanel.show([{ component: promotionProductScan }], {category: this.templatePayload.category}, { backgroundIsDisabled: true })
        },
        closeError() {
            this.invalid = false;
        },
        handleSuccessfulSavePrompt(closeSidePanel) {
            if(closeSidePanel){
                this.$sidePanel.close()
            }
        },

        selectedElementChanged(selectedElements){
            this.selectedItems = selectedElements
        },
        async save(closeSidePanel=false){
            this.saving = true;

            const sizeToSave = this.selectedItems.length
            if(sizeToSave > 0){
                const editedPromotion = cloneDeep(this.getPromotion)

                this.selectedItems.forEach(item => {
                    editedPromotion.addItem(item, this.templatePayload?.category)
                })
                let savedItems = []
                try{
                    savedItems = await promotionClient.addPromotionItems(editedPromotion.discount.items, editedPromotion.discount)
                    this.addPromotionItems(savedItems)
                }
                catch(error){
                    prompts.error({text: 'error'})
                    return
                }

                let text = ''
                if(savedItems.length !== sizeToSave){
                    text+= this.$t('Promotion.Messages.AtLeastOneItemNotIncluded')
                }
                text += `${savedItems.length}/${sizeToSave} ${this.$t('Promotion.Messages.AddItemToPromotionOk')}`
                prompts.info({text}).then(this.handleSuccessfulSavePrompt(closeSidePanel))
            }
            else{
                prompts.info({text: this.$t('Promotion.Messages.NoPromotionItemToSave')})
            }
            this.saving = false;
        },
        async addItemToPromotionDiscount(){
            await this.save(true)
        },
        async addCurrentItemToPromotionDiscount(){
            await this.save()
        }
    }
}
</script>