import * as ItemClient from "@/backoffice/modules/item/item.client.js"
import { convertApiItemToItem } from '@/backoffice/modules/item/domain/item.js'
import { ItemWithCost } from '@/backoffice/modules/item/itemWithCost'

export async function getItems(params) {
  const response = await ItemClient.getProducts(params)

  if(response.status === 200) {
    const products = response.data.map(d => convertApiItemToItem(d))
    return products
  }
  else {
    throw response;
  }
}

export async function getItemByUpc(upc) {
  const response = await ItemClient.getProduct(upc, false)

  if(response.status === 200) {
    const item = convertApiItemToItem(response.data)
    return item
  }
  else {
    throw response;
  }
}

export async function getItemsWithCosts(itemWithCostSearchCriteria) {
  const response = await ItemClient.getItemsWithCosts(itemWithCostSearchCriteria);
  return response.status === 200 ? response.data.map(d => new ItemWithCost(d)) : []
}

export async function getItemCost(itemSKU) {
  const costResponse = await ItemClient.getSingleCostByCode([{ "Column": "ItemSKU", "Operator": "=", "Value": [itemSKU] }]);
  return costResponse.data
}