<template src='./promotion-product-scan.html'></template>

<script>
import { mapGetters } from 'vuex';
import PromotionProductAdd from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-add/promotion-product-add.vue';
import PromotionProductSearch from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-search/promotion-product-search.vue';
import ScanProduct from '@/core/components/layout/side-panel/scan-product/scan-product.vue'
import { PromotionItemCategory } from '@/backoffice/modules/promotion/domain/promotion';
import { isWeightItem } from '@/backoffice/modules/item/domain/item'
import { WeightDiscountTypeCode } from '@/backoffice/modules/promotion/domain/promotion';

export default {
  name: 'promotion-product-scan',

  props: {
    templatePayload: {
      type: Object,
      default: function(){
        return { category: PromotionItemCategory.GetY}
      }
    }
  },

  components: {
    ScanProduct
  },

  computed: {
    ...mapGetters('Promotion', ['getPromotion', 'getPromotionDescription']),
    itemCategory(){
      if(!this.templatePayload?.category || !this.getPromotion?.discount?.isBxGy){
        return ''
      }
      return this.$t(`Promotion.ItemCategory.${this.templatePayload.category}`)
    }
  },
  methods: {
    isValidItemPredicate(item) {
      return !(isWeightItem(item) && !WeightDiscountTypeCode.has(this.getPromotion?.discount?.typeCode))
    },
    cancel() {
      this.$sidePanel.close();
    },

    search() {
      this.$sidePanel.show([{ component: PromotionProductSearch }], {category: this.templatePayload?.category}, { classes: ['side-panel--sticky-footer'] });
    },

    select(item) {
      this.$sidePanel.show([{ component: PromotionProductAdd }], { item: item, category: this.templatePayload?.category });
    }
  }
}
</script>
