<template src="./options-tool-panel.html"></template>

<style lang="scss">
@import "./options-tool-panel.scss";
</style>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'OptionsToolPanel',

    mounted() {
        this.$nextTick(() => {
            this.init();
        });
    },
    data() {
        return {
            pageSize: null
        }
    },
    computed: {
        ...mapGetters('Grid', ['getUserGridState']),
        ...mapGetters('Account', ['getCurrentTargetLayerId', 'getUser']),
        displayResetButton() {
            return this.params.gridId
        },
        disabledResetButton() {
            return !this.userGridState
        },
        userGridState() {
            const userGridState = this.getUserGridState[this.getUser._UserID]
            if (!userGridState) return null
            const gridState = userGridState[this.params.gridId]
            if (!gridState) return null
            const targetLayerState = gridState[this.getCurrentTargetLayerId]
            return targetLayerState || null
        },
        pageSizes() {
            return [15, 50, 100, 500]
        },
        paginationEnabled() {
            return this.params.api.paginationProxy.active
        }
    },

    methods: {
        ...mapMutations('Grid', { setUserGridState: 'SET_USER_GRID_STATE' }),
        init() {
            let pageSize = this.userGridState?.pageSize
            
            if (!pageSize)
                pageSize = 100
            
            this.updatePageSizePicker(pageSize)
            this.params.api.paginationSetPageSize(pageSize)
        },
        updatePageSizePicker(pageSize) {
            this.pageSize = pageSize
        },
        changePageSize() {            
            this.params.api.paginationSetPageSize(this.pageSize)

            const state = this.userGridState || {}
            state.pageSize = this.pageSize
            
            this.setUserGridState({
                gridId: this.params.gridId,
                userId: this.getUser._UserID,
                targetLayerId: this.getCurrentTargetLayerId,
                gridState: state
            })
        },
        resetUserGridState() {
            this.params.api.closeToolPanel()
            this.params.resetGridState()
        }
    }
}
</script>