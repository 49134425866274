import { reportClient } from '@/backoffice/modules/reporting/services/report.client.js';
import { genericServices } from '@/core/services/generic/generic.services';
import { reportUserCustomizationServices } from '@/backoffice/modules/reporting/services/report-user-customization.services.js';

const getDefaultState = () => {
  return {
    reportList: [],
    reportDataModel: {
      dataSource: null,
      descriptions: [],
      isDeleted: false,
      period: '-0d',
      genericListColumn: [],
      genericListFilter: [],
      genericListGroup: [],
      _ReportDataModelID: 0,
      _TargetID: 0,
      _UserID: 0,
      isList: false,
      reportDataModelRoles: []
    },
    reportUserCustomization: {
      _ReportUserCustomizationID: 0,
      _UserID: 0,
      _ReportDataModelID: 0,
      period: null,
      listFilter: null,
      customization: null,
      _WidgetUserCustomizationID: 0,
      listSorting: null,
      pivot: false
    },
    scope: {},
    reportData: []
  }
}

export const Reporting = {
  namespaced: true,
  name: 'Reporting',
  state: getDefaultState(),
  getters: {
    reportList: (state) => { 
      return state.reportList; 
    },
    reportDataModel: (state) => { 
      return state.reportDataModel; 
    },
    reportUserCustomization: (state) => { 
      return state.reportUserCustomization; 
    },
    scope: (state) => { 
      return state.scope;
    }
  },

  mutations: {
    //Reset store
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },

    //Report Data Model mutations
    SET_REPORT_LIST(state, reportList) {
      state.reportList = reportList;
    },
    SET_REPORT_DATA_MODEL(state, reportDataModel) {
      state.reportDataModel = reportDataModel;
    },
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData;
    },
    SET_PERIOD(state, period){
      state.reportDataModel.period = period;
    },
    SET_SCOPE(state, scope){
      state.scope = scope;
      state.reportDataModel._TargetID = scope.id;
    },
    SET_REPORT_DATA_MODEL_ROLES(state, reportDataModelRoles){
      state.reportDataModel.reportDataModelRoles = [...reportDataModelRoles];
    },
    SET_GENERIC_LIST_FILTER(state, filters){      
      state.reportDataModel.genericListFilter = filters;
    },
    SET_GENERIC_LIST_COLUMN(state, genericListColumn){      
      state.reportDataModel.genericListColumn = [...genericListColumn];
    },
    SET_GENERIC_LIST_GROUP(state, genericListGroup){      
      state.reportDataModel.genericListGroup = [...genericListGroup];
    },

    //Report User customization mutations
    SET_REPORT_USER_CUSTOMIZATION(state, reportUserCustomization){
      state.reportUserCustomization = reportUserCustomization;
    }
  },

  actions: {
    async getReportList({ commit }, modelName) {
      const filters = [{Column: 'IsDeleted', Value: [false]}];
      const response = await reportClient.getReportList(modelName, true, filters);

      if(response.status == 200){
        commit('SET_REPORT_LIST', response.data);
        return response.data;
      }
      else {
        commit('SET_REPORT_LIST', null);
        throw new Error(response);
      }
    },

    async fetchReportDataModel({ commit }, { _ReportDataModelID, getChildren }) {
      const response = await reportClient.getReport(_ReportDataModelID, getChildren);

      if(response.status == 200){
        commit('SET_REPORT_DATA_MODEL', response.data);
        return response.data;
      }
      else {
        commit('SET_REPORT_DATA_MODEL', null);
        throw new Error(response);
      }
    },

    async getReportData({ commit }, request) {  
      const response = await  genericServices.getEntities(request.params, request.cancelToken);

      if(response.status == 200){
        return response.data;
      }
      else {
        throw new Error(response);
      }
    },

    async getReportDataFromRdm({ commit }, request) {  
      const response = await  genericServices.getEntitiesFromRdm(request.params, request.cancelToken);
  
      if(response.status == 200){
        return response.data;
      }
      else {
        throw new Error(response);
      }
    },
  
    async fetchReportUserCustomization({ commit }, { _ReportDataModelID, getChildren }) {
      const response = await reportUserCustomizationServices.getReportCustomization(_ReportDataModelID, getChildren);

      if(response.status == 200){
        commit('SET_REPORT_USER_CUSTOMIZATION', response.data);
      }
      else if (response.status == 204){
        commit('SET_REPORT_USER_CUSTOMIZATION', {});
      }
      else {
        throw new Error(response);
      }
    },
  
    async createReportUserCustomization({ commit }, ruc) {  
      const response = await reportUserCustomizationServices.createReportCustomization(ruc);
  
      if(response.status == 200){
        commit('SET_REPORT_USER_CUSTOMIZATION', response.data[0]);
        return response.data[0];
      }
      else {
        throw new Error(response);
      }
    },
  
    async updateReportUserCustomization({ commit }, ruc) {
      const response = await reportUserCustomizationServices.updateReportCustomization(ruc);
  
      if(response.status == 200){
        commit('SET_REPORT_USER_CUSTOMIZATION', response.data[0]);
        return response.data[0];
      }
      else {
        throw new Error(response);
      }
    },  
    
    async deleteReportUserCustomization({ commit }, ids) {  
      const response = await reportUserCustomizationServices.deleteReportCustomization(ids);
  
      if(response.status == 200){
        return response.data;
      }
      else {
        throw new Error(response);
      }
    },
  
    async createReport({ commit }, model) {  
      const response = await reportClient.createReport(model);
  
      if(response.status == 200){
        commit('SET_REPORT_DATA_MODEL', response.data[0]);
        return response.data[0];
      }
      else {
        throw new Error(response);
      }
    },
  
    async updateReport({ commit }, model) {  
      const response = await reportClient.updateReport(model);
  
      if(response.status == 200){
        commit('SET_REPORT_DATA_MODEL', response.data[0]);
        return response.data[0];
      }
      else {
        throw new Error(response);
      }
    },
    
    async deleteReport({ commit }, ids) {  
      const response = await reportClient.deleteReport(ids);
  
      if(response.status == 200){
        return true;
      }
      else {
        throw new Error(response);
      }
    },

    async getDailyProducts({ commit }, request) {
      const response = await reportClient.getDailyProducts(request.startDate, request.endDate, request.targetLayerId);
  
      if(response.status == 200){
        return response.data;
      }
      else {
        throw response
      }
    },

    async getCurrentInventories({ commit }, targetLayerId) {
      const response = await reportClient.getCurrentInventories(targetLayerId);
  
      if(response.status == 200){
        return response.data;
      }
      else {
        throw response
      }
    },

    async getPromotionsReport({ commit }, request) {
      const response = await reportClient.getPromotionsReport(request.startDate, request.endDate, request.targetLayerId);
  
      if(response.status == 200){
        return response.data;
      }
      else {
        throw response
      }
    },

    async getExceptionsReport({ commit }, request) {
      const response = await reportClient.getExceptionsReport(request.startDate, request.endDate, request.targetLayerId);
  
      if(response.status == 200){
        return response.data;
      }
      else {
        throw response
      }
    },

    async getGeneralLedgerReport({ commit }, request) {
      const response = await reportClient.getGeneralLedgerReport(request.startDate, request.endDate, request.targetLayerId);
  
      if(response.status == 200){
        return response.data;
      }
      else {
        throw response
      }
    }
  }  
}
