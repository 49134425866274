<template src="./movement-report.html"></template>

<script>
import { mapActions, mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue';
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue';
import grid from '@/core/components/common/grid/grid.vue';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import getters from '@/core/components/common/grid/getters/getters.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import printReport from '@/backoffice/modules/reporting/mixins/print-report/print-report.js';
import reportFilename from '@/backoffice/modules/reporting/mixins/report-filename/get-report-filename.js';

export default {
    name: 'Movement-Report',
    mixins: [extractTime],
    components: {
        searchBar,
        targetDropdown,
        periodPicker,
        grid,
    },

    data() {
        return {
            gridReadyPromiseResolver: null,
            targetChangedEventPromiseResolver: null,
            componentInitialized: false,
            filters: null,
            period: '-0w',
            startDate: null,
            endDate: null,
            targetLayerId: null,
            targetDropdownKey: 0,
            movements: [],
            gridOptions: {
                sideBar: true,
                rowGroupPanelShow: 'always',
                groupIncludeTotalFooter: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: 'agSetColumnFilter'
                },
                columnDefs: [
                    {
                        headerName: this.$t('MovementReport.TargetLayerType'), headerTooltip: this.$t('MovementReport.TargetLayerType'),
                        children: [
                            { field: 'enterprise', hide: true, headerName: this.$t('MovementReport.Enterprise'), headerTooltip: this.$t('MovementReport.Enterprise'), enableRowGroup: true, rowGroupIndex: 0 },
                            { field: 'store', hide: true, headerName: this.$t('MovementReport.Store'), headerTooltip: this.$t('MovementReport.Store'), enableRowGroup: true, rowGroupIndex: 1 },
                            { field: 'pos', hide: true, headerName: this.$t('MovementReport.Pos'), headerTooltip: this.$t('MovementReport.Pos'), enableRowGroup: true },
                        ]
                    },
                    {
                        headerName: this.$t('MovementReport.Product'), headerTooltip: this.$t('MovementReport.Product'),
                        children: [
                            { field: 'upc', hide: true, headerName: this.$t('MovementReport.ProductUpc'), headerTooltip: this.$t('MovementReport.ProductUpc'), enableRowGroup: true },
                            { field: 'productDescription', headerName: this.$t('MovementReport.ProductDescription'), headerTooltip: this.$t('MovementReport.ProductDescription'), enableRowGroup: true },
                            { field: 'brandDescription', hide: true, headerName: this.$t('MovementReport.Brand'), headerTooltip: this.$t('MovementReport.Brand'), enableRowGroup: true },
                        ],
                    },
                    {
                        headerName: this.$t('MovementReport.SubDepartment'), headerTooltip: this.$t('MovementReport.SubDepartment'),
                        children: [
                            { field: 'subDepartmentCode', hide: true, headerName: this.$t('MovementReport.SubDepartmentCode'), headerTooltip: this.$t('MovementReport.SubDepartmentCode'), enableRowGroup: true, },
                            { field: 'subDepartmentDescription', hide: true, headerName: this.$t('MovementReport.SubDepartment'), headerTooltip: this.$t('MovementReport.SubDepartment'), enableRowGroup: true, rowGroupIndex: 3 },
                        ]
                    },
                    {
                        headerName: this.$t('MovementReport.Department'), headerTooltip: this.$t('MovementReport.Department'),
                        children: [
                            { field: 'departmentCode', hide: true, headerName: this.$t('MovementReport.DepartmentCode'), headerTooltip: this.$t('MovementReport.DepartmentCode'), enableRowGroup: true },
                            { field: 'departmentDescription', hide: true, headerName: this.$t('MovementReport.Department'), headerTooltip: this.$t('MovementReport.Department'), enableRowGroup: true, rowGroupIndex: 2 },
                        ]
                    },
                    {
                        headerName: this.$t('MovementReport.Format'), headerTooltip: this.$t('MovementReport.Format'),
                        children: [
                            { field: 'formatQuantity', hide: true, headerName: this.$t('MovementReport.FormatQuantity'), headerTooltip: this.$t('MovementReport.FormatQuantity'), enableValue: true, filter: 'agNumberColumnFilter' },
                            { field: 'formatValue', hide: true, headerName: this.$t('MovementReport.FormatValue'), headerTooltip: this.$t('MovementReport.FormatValue'), enableValue: true, filter: 'agNumberColumnFilter' },
                            { field: 'formatUnitOfMeasure', hide: true, headerName: this.$t('MovementReport.FormatUnitOfMeasure'), headerTooltip: this.$t('MovementReport.FormatUnitOfMeasure'), enableRowGroup: true },
                        ]
                    },
                    {
                        headerName: this.$t('MovementReport.Date'), headerTooltip: this.$t('MovementReport.Date'),
                        children: [
                            { field: 'date', hide: true, headerName: this.$t('MovementReport.Date'), headerTooltip: this.$t('MovementReport.Date'), enableGroup: true, valueGetter: getters.dateGetter }
                        ]
                    },
                    {
                        headerName: this.$t('MovementReport.Metrics'), headerTooltip: this.$t('MovementReport.Metrics'),
                        children: [
                            { field: 'startInventory', headerName: this.$t('MovementReport.StartInventory'), headerTooltip: this.$t('MovementReport.StartInventory'), enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                            { field: 'saleQuantity', headerName: this.$t('MovementReport.Sales'), headerTooltip: this.$t('MovementReport.Sales'), valueGetter: getters.negativeValue, enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                            { field: 'receivingQuantity', headerName: this.$t('MovementReport.Receivings'), headerTooltip: this.$t('MovementReport.Receivings'), enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                            { field: 'adjustmentPlus', headerName : this.$t('MovementReport.AdjustmentPlus'), headerTooltip: this.$t('MovementReport.AdjustmentPlus'), enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum'},
                            { field: 'adjustmentMinus', headerName : this.$t('MovementReport.AdjustmentMinus'), headerTooltip: this.$t('MovementReport.AdjustmentMinus'), enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum'},
                            { field: 'endInventory', headerName: this.$t('MovementReport.EndInventory'), headerTooltip: this.$t('MovementReport.EndInventory'), enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                            { field: 'endInventoryValue', headerName: this.$t('MovementReport.EndInventoryValue'), headerTooltip: this.$t('MovementReport.EndInventoryValue'), valueGetter: getters.nonAvailableGetter, valueFormatter: formatters.currencyFormatter, enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                        ]
                    }
                ],
                onGridReady: () => {
                    this.gridReadyPromiseResolver();
                }
            },
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayer','getCurrentTargetLayerId']),
        rowData(){
            return this.movements
        }
    },

    watch: {
        getCurrentTargetLayerId() {
            this.targetDropdownKey++;
        }
    },

    created() {
        Promise.all([
            new Promise(resolve => {
                this.gridReadyPromiseResolver = resolve;
            }),
            new Promise(resolve => {
                this.targetChangedEventPromiseResolver = resolve;
            })
        ]).then(async () => {
            await this.fetchMovements();
            this.componentInitialized = true;
        });
    },

    methods: {
        ...mapActions('Reporting', ['getDailyProducts']),
        async fetchMovements() {
            const dates = this.convert(this.period)
            this.startDate = dates.start
            this.endDate = dates.end

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                targetLayerId: this.targetLayerId
            }

            this.gridOptions.api.showLoadingOverlay();
            try {
                this.movements = await this.getDailyProducts(request)
            }
            catch (e) {
                prompts.error({
                    text: this.$t('Reporting.Messages.GetMovementError')
                });
            }

            this.gridOptions.api.hideOverlay();
        },
        periodChanged(period) {
            this.period = period;
            this.fetchMovements();
        },
        onTargetChanged(target) {
            this.targetLayerId = target.targetLayerId;
            if (!this.componentInitialized) {
                this.targetChangedEventPromiseResolver()
            }
            else {
                this.fetchMovements();
            }
        },
        filtersChanged(filters) {
            this.filters = filters;
        },
        onPrint() {
            printReport(this.gridOptions, this.getCurrentTargetLayer, this.convert(this.period), reportFilename(this.$t('Inventory.Report.ReportTitle')))
        }
    }
}
</script>


