const PurchaseDocument = {
	Order: 'Order',
	Receiving: 'Receiving'
};

const OrderStatus = {
	UNDEFINED: 0,
	OPEN: 1,
	CLOSED: 2,
	VOID: 3,
	PENDING: 4,
	PARTIALLYRECEIVED: 5
};

const ReceivingStatus = {
	UNDEFINED: 0,
	OPEN: 1,
	CLOSED: 2,
	VOID: 3
};

const CanSendStatuses = [
	OrderStatus.OPEN,
	OrderStatus.PENDING
]

const calculateOrdersProductCount = (orders) => {
	if (!orders)
		return []
	
	return orders.map(order => {

		if( order.orderBodies ) {
			const productCount = order.orderBodies.reduce((totalQuantity, { qty }) => totalQuantity + qty, 0)
			return {
				...order,
				productCount: productCount
			}
		}
	})
}

export {
	PurchaseDocument,
	OrderStatus,
	ReceivingStatus,
	CanSendStatuses,
	calculateOrdersProductCount
}