<template inline-template v-cloak src="./read-report-list.html"></template>

<script>
import manageColumns from '@/backoffice/modules/reporting/mixins/manage-columns.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { genericServices } from '@/core/services/generic/generic.services';
import { EntityServices } from '@/core/store/modules/entity.services.js';
import prompts from '@/core/tools/notifications/notifications';
import { RouteType } from '@/core/router/route-type.js'

import favoriteTitle from "@/core/components/common/favorite-title/favorite-title.vue"
import creationButton from '@/core/components/common/creation-button/creation-button.vue'
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import grid  from  '@/core/components/common/grid/grid.vue'
import descriptionTitle from '@/core/components/common/description-title/description-title.vue'
import contextMenu from '@/core/components/common/context-menu/context-menu.vue'

export default {
  mixins: [manageColumns],
  name: 'readReportList',
  components: {
    favoriteTitle,    
    creationButton,   
    searchBar,       
    grid,            
    descriptionTitle, 
    contextMenu      
  },
  props: { 
    reportType: String,
    _ReportDataModelID: [String, Number]
  },
  data() {
    let gridReadyPromiseResolver;
    const gridReadyPromise = new Promise(resolve => { gridReadyPromiseResolver = resolve });
    
    return {
      searchBarKey: 0,
      gridReadyPromiseResolver,
      gridReadyPromise,
      reportTypePromise: null,
      dataPromise: null,
      reportTitleValue: null,
      contextMenuButtons: [
        {
          button: 'editReportDataModel',
          icon: ['fal', 'fa-pencil'],
          text: 'Button.Edit',
          isDisabled: false,
          callback: () => { this.$router.push({ name: `${this.$route.meta.associatedParentRouteName}${RouteType.Update}`, params: { _ReportDataModelID: this.currentReportDataModelID }}); }
        },
        {
          button: 'createReportDataModel',
          icon: ['fal', 'fa-layer-plus'],
          text: 'Button.Create',
          isDisabled: false,
          callback: () => { this.$router.push({ name: `${this.$route.meta.associatedParentRouteName}${RouteType.Create}`}); }
        },
        {
          button: 'listReportDataModel',
          icon: ['fal', 'fa-layer-group'],
          text: 'Button.List',
          callback: () => { this.$router.push({ name: `${this.$route.meta.associatedParentRouteName}`, query: { bypassFavoriteOrDefaultRedirect: true } }); }
        }
      ],
      gridOptions: {
        headerHeight: 50,
        groupHeaderHeight: 50,
        rowSelection: "single",
        rowMultiSelectWithClick: false,
        defaultColDef: {
          resizable: true,
          headerCheckboxSelectionFilteredOnly: false,
        },
        suppressDragLeaveHidesColumns: true,
        columnDefs: [],
        onGridReady: () => this.gridReadyPromiseResolver(), 
      },
      filters: [],
      reports: [],
      grouping: null
    };
  },
  computed: {
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'hasPermission', 'getReportFavorite']),
    ...mapGetters('App', ['isMobile']),
    ...mapGetters('DataModels', ['models']),
    ...mapGetters('Reporting', ['reportList', 'reportDataModel']),
    modelMetadata() {
      return this.models[this.reportType]
    },
    showPreviousGroupHeader() {
      return this.grouping && !!this.grouping.groups.length;
    },
    previousField() {
      const previousField = this.grouping &&  this.grouping.groups.length && this.grouping.groups[this.grouping.groups.length - 1].Column;
      return this.modelMetadata && previousField && this.$t(`${this.modelMetadata.name}.Columns.${this.capitalizeFirstLetter(previousField)}`);
    },
    canCreate() {
      return this.modelMetadata && this.modelMetadata.permissions && this.hasPermission([this.modelMetadata.permissions.create]);
    },
    reportTitleFavoriteID() {
      return this.getReportFavorite && this.getReportFavorite.reportDataModelID || null;
    },
    currentReportDataModelID() {
      return this._ReportDataModelID && Number.parseInt(this._ReportDataModelID)
    },
    reportListOptions() {
      return {
        id: 'ReportTitle',
        options: this.reportList || [],
        trackBy: '_ReportDataModelID',
        label: 'description',
        multiple: false,
        placeholder: '',
        showLabels: false,
        searchable: false,
        allowEmpty: false
      }
    },
    bypassFavoriteOrDefaultRedirect(){
      return this.$route.query && this.$route.query.bypassFavoriteOrDefaultRedirect
    }
  },
  watch:{
    getCurrentTargetLayerId(newVal, oldVal){
      if(newVal && newVal != oldVal){
        this.$router.push({ name: this.$route.meta.associatedParentRouteName });
      }
    },
    isMobile(newVal, oldVal){
      if(newVal != oldVal){
        this.setColumnDefs();
      }
    },
    reportType: {
      handler: async function (newVal) {
        if (newVal) {
          let reportTypePromiseResolver;
          this.reportTypePromise = new Promise(resolve => { reportTypePromiseResolver = resolve })

          await this.getReportList(newVal)

          this.reportTitleValue = this.reportList.find(r => r._ReportDataModelID == this.currentReportDataModelID);
          
          await this.getModel(newVal);        

          reportTypePromiseResolver()
        }
      },
      immediate: true
    },
    _ReportDataModelID: {
      handler: async function (newVal) {
        if (newVal) {
          let reportDataModelPromiseResolver;

          //promise is used as a workaround instead of mounted+beforeRouteUpdate as of vue-router bug #444
          //https://github.com/vuejs/router/issues/444 & it's PR https://github.com/vuejs/router/pull/446
          Promise.all([
            this.gridReadyPromise,
            this.reportTypePromise,
            new Promise(resolve => { reportDataModelPromiseResolver = resolve })
          ]).then(() => {
            this.getData()
          })
          
          await this.fetchReportDataModel({
              _ReportDataModelID: newVal,
              getChildren: true
            });

          this.setBreadcrumbContext({ reportDataModel: this.reportDataModel })
          this.grouping = {
            currentField: null,
            groups: []
          }

          reportDataModelPromiseResolver()
        }
      },
      immediate: true
    },
  },

  beforeDestroy() {
    if(this.gridOptions.api){
      this.gridOptions.api.setRowData([]);
      this.gridOptions.api.destroy();
      this.gridOptions = null;
    }
  },
  methods: {
    ...mapActions('Account', ['manageReportFavorite', 'deleteReportFavorite']),
    ...mapActions('Reporting', ['getReportList', 'fetchReportDataModel']),
    ...mapActions('DataModels', ['getModel']),
    ...mapMutations('Breadcrumb', {
      setBreadcrumbContext: 'SET_CONTEXT'
    }),

    async getData() {
      const apiResponse = await genericServices.getGenericApiEntities(this.reportType, true, this.currentReportDataModelID, this.grouping.groups);
      if (apiResponse.status == 200) {
        const data = apiResponse.data || [];
        const deconstructedData = this.setData(data);
        this.gridOptions.api.setRowData(deconstructedData);
        this.setColumnDefs();
      }
    },

    setColumnDefs(){
      this.searchBarKey++;
      const modelMetadata = this.models[this.reportType]
      this.gridOptions.api.setColumnDefs([]);
      //Check if there is a previousField to show it in the UI
      //Is grouped

      const hasGroups = this.reportDataModel && this.reportDataModel.genericListGroup && !!this.reportDataModel.genericListGroup.length;
      const isNotLastGroup = hasGroups && this.grouping.groups.length < this.reportDataModel.genericListGroup.length;
      if(isNotLastGroup){

        const currentField = this.reportDataModel.genericListGroup[this.grouping.groups.length];
        this.grouping.currentField = currentField;

        this.gridOptions.columnDefs = [
          {
            field: currentField,
            headerName: this.$t(`${modelMetadata.name}.Columns.${this.capitalizeFirstLetter(currentField)}`),
            cellRenderer: "groupRenderer",
            cellRendererParams: {
              changeGroup: this.changeGroup
            },
            autoHeight: true
          }
        ];
      }
      //Either is not grouped, or last dataset
      else
      {
        const columnDefs = this.setAgGridColumns(
          modelMetadata.columnDefs || [],
          this.reportDataModel.genericListColumn,
          [],
          modelMetadata,
          false,
          true,
          {
            model: modelMetadata, 
            delete: this.delete, 
            editEntity: this.editEntity,
            openSidePanel: this.openSidePanel 
          });

        //If mobile, use the first column in genericListColumn to be the default one.
        if (this.isMobile){
          const column = this.reportDataModel.genericListColumn[0];
          this.gridOptions.columnDefs = [{ 
            field: column.name, 
            headerName: this.$t(`${modelMetadata.name}.Columns.${this.capitalizeFirstLetter(column.name)}`), 
            cellRenderer: "dropdownRenderer",
            cellRendererParams: (params) => {
              return {
                columnDefs: columnDefs,
                openSidePanel: this.openSidePanel,
                canDelete: () => params.data.canDelete,
                onDelete: this.delete
              }
            },
            cellClass: this.isMobile ? "dropdown-cellrenderer" : "",
            autoHeight: true
          }];
        }  
        //Else, set all columns normally
        else{
          this.gridOptions.columnDefs = columnDefs;
        }
      }

      this.gridOptions.api.setColumnDefs(this.gridOptions.columnDefs);
      this.autoSizeAll();
    },
    setData(res){
      const rows =[];
      for (let i = 0; i < res.length; i++) {
        rows.push(this.deconstruct(res[i], res[i]));
      }
      return rows;
    },
    addRow(row) {
      if (!row) {
        this.getData();
        return;
      }

      let id = 0;
      let key;
      for (let i = 0; i < this.modelMetadata.properties.length; i++) {
        if(this.hasOwnPropertyCI(this.modelMetadata.properties[i], 'keyAtt')){
          id = row[this.modelMetadata.properties[i].name];
          key = this.modelMetadata.properties[i].name;
          break;
        }
      }

      this.gridOptions.api.deselectAll();
      this.gridOptions.api.applyTransaction({ add: [row] });

      setTimeout(()=> {
        this.gridOptions.api.forEachNode((node) => {
          if(this.hasOwnPropertyCI(node.data, key)){
            if(node.data[key] == id) {
              node.setSelected(true);
              this.gridOptions.api.ensureIndexVisible(node.rowIndex);
            }
          }
        });
      });
    },

    changeGroup(data, foward){
      if (foward) {
        this.grouping.groups.push({ Column: this.grouping.currentField , Value: [data[this.grouping.currentField]] });
      } 
      else {
        this.grouping.groups.pop();
      }

      this.getData();
    },
    autoSizeAll() {
      if(this.isMobile || this.gridOptions.columnDefs.length < 8){
        this.gridOptions.api.sizeColumnsToFit();
      }
      else{
        const allColumnIds = [];
        this.gridOptions.columnApi.getColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      }
    },
    readEntity(row){
      this.runReport(row);
    },
    editEntity(entity) {
      this.openSidePanel(entity.data);
    },
    async delete() {    
      const promptResult = await prompts.warning({
        html: this.$t('General.Messages.Delete')
      });

      if (!promptResult.isConfirmed) {
        return
      }

      const rows = this.gridOptions.api.getSelectedRows();
      if(rows.length > 0){
        const ids = [];
        let key;
        for (let i = 0; i < this.modelMetadata.properties.length; i++) {
          if(this.hasOwnPropertyCI(this.modelMetadata.properties[i], 'keyAtt')){
            key = this.modelMetadata.properties[i].name;
            if(key == "ID"){
              key = "iD";
            }
            break;
          }
        }
        for (let i = 0; i < rows.length; i++) {
          if(this.hasOwnPropertyCI(rows[i], key)){
            ids.push(rows[i][key]);
          }
        }

        const request = {
          targetModel: this.reportType,
          ids: ids
        }
        let result = null;
        try {
          result = await EntityServices.deleteEntities(request);
        }
        catch (e) {       
          if(e.metaData && e.metaData.message){
            if(e.metaData.message[0]?.code === 'GeneriqueFastlaneUserExists'){
              await prompts.error({html: this.$t('General.Messages.GenericFastlaneUserExists')})
            }
          }
        }
        if(result){
          this.gridOptions.api.applyTransaction({remove: rows });
        }
      }      
    },
    filtersChanged(filters){
      this.filters = filters;
    },

    async openSidePanel(data) {
      let promise = this.$sidePanel.show(this.$route.meta.sidePanelComponents, data)

      let returnValue;
      do
      {
        returnValue = await promise

        if (!returnValue) {
          return
        }

        if (returnValue.save) {
          this.getData();
        }
        else if (returnValue.create) {
          this.addRow(returnValue.row);
        }

        promise = this.$sidePanel.getNextUpdate();
      }
      while(promise)
    },
    reportTitleSelectionChanged(newElement) {
      this.reportTitleValue = newElement
      this.$router.replace({ 
        name: this.$route.name,
        params: {
          _ReportDataModelID: newElement._ReportDataModelID
        }
      })
    },
    async reportTitleFavoriteChanged(newID) {
      const newReportFavorite = { 
        ...this.getReportFavorite || { type: this.reportType },
        reportDataModelID: newID 
      }
      await this.manageReportFavorite(newReportFavorite);
    },

    async reportTitleFavoriteDeleted() {
      await this.deleteReportFavorite(this.getReportFavorite);
    }
  }
};
</script>
