import Vue from 'vue';
import sidePanel from './side-panel.vue';
import isEqual from 'lodash/isEqual'

class Component {
    constructor(component, tab){
        this.component = component
        this.tab = tab
    }
}

class SidePanelOptions {
    constructor(containerId = 'body'){
        this.containerId = containerId
    }
}

class Options {
    constructor(plugins = null, sidePanelOptions = null){
        this.plugins = plugins
        this.sidePanelOptions = sidePanelOptions
    }
}

class SidePanel {
    instance
    sidePanel
    plugins = {}
    sidePanelID = 'side-panel-id';
    sidePanelOptions = new SidePanelOptions();
    resolvePromise = Function;
   
    constructor() {
        this.resolvePromise = () => { return; };
    }
   
    /**
     * Function
     * @description Function call by Vue when add the plugin. 
     * @param {Function} Vue Vue instance
     * @param {object} plugins? Vue plugins (store, i18n)
     * @param {object} sidePanelOptions as SidePanelOptions? { containerId: string }
     */
    install(Vue, options = new Options()) {
        Vue.prototype['$sidePanel'] = this;

        if (options.plugins) {
            this.plugins = options.plugins;
        }

        if (options.sidePanelOptions) {
            this.sidePanelOptions = (options.sidePanelOptions);
        }
    }

    /**
     * Function
     * @description Function for display the SidePanel. If is already displayed, destroy it and recreate it only if the first Component is different or as is the same with a payload.
     * @param {array<Component>} components SidePanel TemplateList Component
     * [{
     *      component: Component of the SidePanel Template
     *      tab: Tab description | TODO: Delete it if have time (Address, User, UserAccessList, Hierarchy, Role) 
     * }]
     * @param {object} payload Payload for the Component
     * @param {object} options Options for the SidePanel
     * {
     *     classes: CSS class for the Modal
     * }
     */
    show(components, payload, options) {
        if (components.length && this.isAlreadyDisplayed((components[0].component)) && (!payload || isEqual(payload, this.sidePanel.payload))) {
            return Promise.resolve();
        }

        return new Promise(resolve => {
            this.resolvePromise = resolve;

            this.create();
            
            this.instance = new Vue({
                ...this.plugins,
                render: h => h(sidePanel, {
                    props: {
                        components: components,
                        payload: payload,
                        options: options
                    }
                })
            }).$mount(`#${this.sidePanelID}`);

            this.sidePanel = this.instance.$children[0];
        });
    }
   
    /**
     * Function
     * @description Function for validate if the current Component displayed in the SidePanel.
     * @param {Component} component Vue Component you would check if it is already displayed in the SidePanel.
     */
    isAlreadyDisplayed(component) {
        if (!this.sidePanel || !this.sidePanel.loadedComponent) {
            return false;
        }

        return this.sidePanel.loadedComponent === component;
    }

    /**
     * Function
     * @description Function for set the property disabledTabs in the SidePanel.
     * @param {boolean} value True/False
     */
    disabledTabs(value) {
        if (!this.sidePanel) {
            return;
        }

        this.sidePanel.tabsIsDisabled = value;
    }

    /**
     * Function
     * @description Function for set the property disabledBackground in the SidePanel.
     * @param {boolean} value True/False
     */
    disabledBackground(value) {
        if (!this.sidePanel) {
            return;
        }

        this.sidePanel.backgroundIsDisabled = value;
    }

    /**
     * Function
     * @description Function for set the property isEdited in the SidePanel.
     * @param {boolean} value True/False
     */
    isEdit(value) {
        if (!this.sidePanel) {
            return;
        }
        
        this.sidePanel.isEdited = value;
    }

    /**
     * Function
     * @description Function to navigate to the next tab.
     */
     nextTab() {
        if ((this.sidePanel.currentTabIndex + 1) == this.sidePanel.tabs.length) {
            this.sidePanel.currentTabIndex = 0;
        }
        else{
            this.sidePanel.currentTabIndex++;
        }     
    }

    /**
     * Function
     * @description Function for resolve the Promise and destroy the SidePanel.
     * @param {object} data Data return by the SidePanel in the Promise.
     */
    submit(data) {
        this.resolvePromise(data);
        this.destroy();
    }

    /**
     * Function
     * @description Function for resolve the Promise.
     * @param {object} data Data return by the SidePanel in the Promise.
     */
    update(data){
        this.resolvePromise(data);
    }

    /**
     * Function
     * @description Function to get a new Promise from the sidepanel already open.
     */
    getNextUpdate(){
        return new Promise(resolve => {
            this.resolvePromise = resolve;
        });
    }

    /**
     * Function
     * @description Function for resolve the Promise with empty Data and destroy the SidePanel.
     */
    close() {
        this.resolvePromise();
        this.destroy();
    }

    /**
     * Function
     * @description Function for create the SidePane element and add it to the Container.
     */
    create() {
        const sidePanel = document.getElementById(this.sidePanelID);

        if (sidePanel) {
            this.close();
        }

        const div = document.createElement('div');
        div.setAttribute('id', this.sidePanelID);

        const containerId = this.sidePanelOptions.containerId || 'body';

        if (containerId == 'body') {
            document.getElementsByTagName('body')[0].appendChild(div);
        }
        else {
            const container = document.getElementById(containerId);

            if (container) {
                container.appendChild(div);
            }
        }
    }

    /**
     * Function
     * @description Function for remove the SidePanel from the dom and destroy is instance in Vue.
     */
    destroy() {
        const div = document.getElementById(this.sidePanelID);

        if (div) {
            div.remove();
            this.sidePanel = null;
    
            this.instance.$destroy();
        }
    }
}

export default new SidePanel();