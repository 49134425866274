<template src="./search-bar.html"></template>

<style scoped lang="scss">@import "./search-bar.scss";</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>

import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { SearchLimitOption } from '@/core/components/layout/side-panel/product-search/product-search.const.js';
import multiselect from 'vue-multiselect';

export default {
  name: 'search-bar',
  props: {
    id: {
      type: String,
      default: 'search-bar-id'
    },
    styles: String,
    multi: {
      default: true,
      type: Boolean
    },
    placeholder: String,
    debounceSearch: Boolean,
    autoFocus: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    searchScope: {
      type: Boolean,
      default: false
    },
    disabledSearchBar: {
      type: Boolean,
      default: false
    },
    limitOptions: {
      type: Array,
      default: () => SearchLimitOption
    }
  },
  components: {
    multiselect
  },
  data() {
    return {
      sValue: "",
      limitSearch: null,
      userDefinedFilters: []
    }
  },
  watch: {
    queryStringFilters: {
      handler() {
        this.emitFilterChanged()
      }
    },
    limitSearch(_, oldValue) {
      if (oldValue)
        this.clearFilters()
    }
  },
  computed: {
    ...mapGetters('App', ['isMobile']),
    queryStringFilters() {
      if (!this.$route) {
        return [];
      }

      const { query } = this.$route;
      const [...searchFilters] = (query.searchFilters || []);
      return searchFilters;
    },
    combinedFilters() {
      return [...this.queryStringFilters, ...this.userDefinedFilters]
    },
    limitSearchValue() {
      return this.limitSearch?.value
    }
  },
  mounted() {
    this.emitFilterChanged()
    this.limitSearch = this.limitOptions[0]
  },
  methods: {
    removeFilter(filter) {

      const initalFilterPosition = this.queryStringFilters.indexOf(filter);
      if (initalFilterPosition > -1) {

        const indexOfRemovedFilter = this.queryStringFilters.indexOf(filter);
        if (indexOfRemovedFilter == -1) {
          return;
        }

        this.queryStringFilters.splice(indexOfRemovedFilter, 1)
        this.$router.replace({
          query: {
            ...this.$route.query,
            searchFilters: this.queryStringFilters
          }
        });
      }
      else {

        const indexOfRemovedFilter = this.userDefinedFilters.indexOf(filter);
        if (indexOfRemovedFilter > -1) {
          this.userDefinedFilters.splice(indexOfRemovedFilter, 1);
          this.emitFilterChanged();
        }
      }
    },

    clearFilters() {
      this.userDefinedFilters = [];

      const query = { ...this.$route.query }
      if (query.searchFilters) {

        delete query.searchFilters
        this.$router.replace({
          query
        });
      }
      else {
        this.emitFilterChanged();
      }
    },

    filter() {
      if (this.multi) {
        return;
      }

      this.userDefinedFilters = [];

      const filter = this.getFilter();
      filter && this.userDefinedFilters.push(filter);

      if (this.debounceSearch) {
        this.debounceEmit();
      }
      else {
        this.emitFilterChanged();
      }
    },
    
    addFilter() {
      if (!this.multi) {
        return;
      }

      const filter = this.getFilter();
      filter && this.userDefinedFilters.push(filter);

      this.emitFilterChanged();
      this.sValue = '';
    },

    getFilter() {
      const searchValue = (this.sValue || '').trim();
      if (searchValue == '') {
        return;
      }

      return this.sValue;
    },

    emitFilterChanged() {
      if(this.searchScope && this.limitSearch?.value !== undefined){
        this.$emit("filters-changed", this.combinedFilters, this.limitSearch.value);
      }
      else {
        this.$emit("filters-changed", this.combinedFilters)
      }
    },

    debounceEmit: debounce(function () {
      this.emitFilterChanged();
    }, 500),

    onInputFocus() {
      this.$emit("inputFocus")
    },
    onInputBlur() {
      this.$emit("inputBlur")
    }
  }
}
</script>