<template src="./numericCellEditor.html"></template>

<script>
const numberOnlyRegex = /^\d+$/;

export default {
  data() {
    return {
      value: ""
    };
  },

  mounted(){
    this.init()
  },

  methods: {
    init() {
      this.value = this.params.value

      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    onInput(e) {
      const value = e.target.value

      if(numberOnlyRegex.test(value) || value === "")
        this.value = value
      else
        this.value = value.replace(/[^0-9]/g, '')
    },
    getValue() {
      return this.value
    }
  }
}
</script>